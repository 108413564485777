<template>
    <div id="timesheetGridUnProj">
        <div class="totals">
            <div class="grouptotal">
                <span class="titletotals" title="Ore Ordinarie">
                    <v-icon title="Ore Ordinarie">mdi-account-hard-hat</v-icon>
                    <span> {{ totPresenzaMese }} H</span>
                </span>
                <span class="detailtotals">{{ totPresenzaMese }} </span>
            </div>
            <div class="grouptotal">
                <span class="titletotals" title="Ore Assenza">
                    <v-icon title="Ore Assenza">mdi-account-minus</v-icon>
                    <span> {{ totAssenzaMese }} H</span>
                </span>
                <span class="detailtotals">{{ totAssenzaMese }} </span>
            </div>

            <div class="grouptotal">
                <span class="titletotals" title="Ore Straordinarie">
                    <v-icon title="Ore Straordinario">mdi-account-plus</v-icon>
                    <span> {{ totStraordinariMese }} H</span>
                </span>
                <span class="detailtotals">{{ totStraordinariMese }}</span>
            </div>

            <div class="grouptotal">
                <span class="titletotals" title="Ore Reperibilità">
                    <v-icon title="Ore Reperibilità">mdi-account-star</v-icon>
                    <span> {{ totReperibilitaMese }} H</span>
                </span>
                <span class="detailtotals">{{ totReperibilitaMese }}</span>
            </div>

            <div class="grouptotal" v-if="showColumns">
                <span class="titletotals" title="Ore Intervento">
                    <v-icon title="Ore Intervento">mdi-account-star</v-icon>
                    <span> {{ totInterventoMese }} H</span>
                </span>
                <span class="detailtotals">{{ totInterventoMese }}</span>
            </div>
        </div>



        <v-layout d-flex align-center justify-center style="min-height: auto;margin-top:4px;">
            <v-card-actions>
                <div class="contcalendar">

                    <datepicker ref="defCalendar" @dayclick="onDayClick" @update:from-page="pageChange" format="yyyy"
                        v-model="date" is-expanded locale="it" color="yellow" is-light :attributes="attributes" />

                </div>
            </v-card-actions>
        </v-layout>

        <rise-loader v-if="showSpinner" :size="'20px'" :color="'#ffd212'" />
        <!--Detail -->

        <!-- start grid -->
        <div>

            <div class="row cont_ts_un_prog">
                <div class=" " style="margin:0 auto;">
                    <div class="card">

                        <div class="card-body" style="padding:0px">

                            <div class="table-responsive" style="">

                                <table id="myTable">
                                    <thead>
                                        <tr>

                                            <th class='all'></th>
                                            <th class='all'>
                                                <div class="cl_giorno">Giorno</div>
                                            </th>
                                            <th class='all cl_h_ordinario'>Ord.</th>
                                            <th class='all cl_h_straordinario'>Straord.</th>
                                            <th class='all cl_h_assenza_ore_1'>Assenza</th>
                                            <th class='all cl_h_assenza_tipo_1'>Tipo Assenza</th>
                                            <th class='all cl_h_totale' style="padding-left: 20px!important;">Totale
                                            </th>
                                            <th class='all cl_h_descrizione' style="padding-left: 20px!important;">
                                                Descr.</th>

                                            <th class='all cl_h_descrizione' style="padding-left: 20px!important;">Sede
                                                Cliente
                                            </th>

                                            <th class='all cl_h_descrizione' style="padding-left: 20px!important;">Rep.
                                            </th>
                                            <th class='all cl_h_descrizione' style="padding-left: 20px!important;">
                                                Interv.</th>
                                            <th class='all cl_h_descrizione' style="padding-left: 20px!important;">Ora
                                                Da</th>
                                            <th class='all cl_h_descrizione' style="padding-left: 20px!important;">Ora A
                                            </th>
                                            <th class='all cl_h_descrizione'>Prog.</th>
                                            <th class='all cl_h_descrizione' style="text-align: right">Nota Spese</th>



                                            <th class='all'></th>

                                        </tr>
                                    </thead>

                                    <tfoot>

                                        <tr>
                                            <th colspan="16">
                                                <div style="height:80px">&nbsp;</div>
                                            </th>
                                        </tr>
                                    </tfoot>

                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- end grid -->

        <v-row justify="center" class="rowStatus" style="align-items:center;">
            <span>STATO</span>
            <span class="spanStatus">
                <v-tooltip v-if="curStatus == 'Open'" bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon color="black" size="30" v-on="on">mdi-account-lock-open</v-icon>
                    </template>
                    <span>{{ curStatus }}</span>
                </v-tooltip>

                <v-tooltip v-if="curStatus == 'Close'" bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon size="30" color="black" v-on="on">mdi-account-lock</v-icon>
                    </template>
                    <span>{{ curStatus }}</span>
                </v-tooltip>

                <v-tooltip v-if="curStatus == 'For approval'" bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon size="30" color="black" v-on="on">mdi-account-arrow-right</v-icon>
                    </template>
                    <span>{{ curStatus }}</span>
                </v-tooltip>

            </span>
        </v-row>

        <!--Detail End -->

        <!--Admin dialog START-->
        <!--  <div ref="ok" id="ok"></div> -->
        <template>
            <v-row justify="center">
                <v-dialog v-model="admindialog" persistent max-width="600px">
                    <template>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Seleziona dipendente</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>

                                    <v-col cols="12" sm="6">
                                        <v-select v-model="currAzienda" v-on:change="syncArrayDipendenti"
                                            :items="aziende" label="Azienda*" required></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select :items="dipendenti" label="Risorsa" v-model="currDipendente"
                                            item-value="v_res_id" item-text="fld_name" @change="changeDip"></v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <small></small>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="admindialog = false; syncMonth();">
                                Close
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="admindialog = false; syncMonth();">
                                Save
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!--Admin dialog END -->



        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">

                                <v-img alt="" src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                                    style="margin-right:10px" />

                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                </v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="(tile, key) in filteredAziende" :key="key"
                                    @click="manageClickAziende(tile)">

                                    <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                    <v-list-item-title class='itemAzienda'>{{ tile }}</v-list-item-title>


                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetDipendenti">
                    <v-sheet class="text-center" height="100vh">

                        <div class="headerSheetAziende" fixed style="position:fixed;">

                            Dipendenti
                            <v-btn class='closeSheet' icon @click="sheetDipendenti = !sheetDipendenti">

                                <v-img alt="" src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                                    style="margin-right:10px" />

                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                    v-model="searchDipendenti"></v-text-field>

                            </v-toolbar>
                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card style="max-height:1300px!important;min-height:1300px!important;">
                                <v-list-item class="text-left" v-for="(objDip, key) in filteredDipendenti" :key="key"
                                    @click="manageClickDipendenti(objDip)">

                                    <v-img alt="" src="@/assets/profilo3.png" max-width="24" max-height="24" />

                                    <v-list-item-title class='itemNumDip'>{{ objDip.n_dip }}</v-list-item-title>

                                    <v-list-item-title class='itemNameDip'>{{ objDip.full_name }}</v-list-item-title>


                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <template>
            <div class="text-center">

                <v-bottom-sheet v-model="sheetStatus">
                    <v-sheet class="text-center" height="300px" style="padding-bottom:100px;">

                        <v-btn class='closeStatusSheet' icon @click="sheetStatus = !sheetStatus">
                            <v-icon>mdi-close-circle-outline</v-icon>
                        </v-btn>



                        <div class="py-3">
                            Stato Timesheet attuale : {{ curStatus }}

                        </div>

                        <v-list-item
                            v-show="curStatus == 'Open' && tile.title != 'Aperto' || curStatus == 'For approval' && tile.title != 'For approval' || curStatus == 'Close' && tile.title != 'Chiuso'"
                            class="text-left" v-for="tile in allStatus" :key="tile.title"
                            @click="changeStatus(tile.title)">
                            <v-list-item-avatar>

                                <v-avatar size="32px" tile>
                                    <v-icon :color="tile.color">{{ tile.icon }}</v-icon>


                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-title>{{ tile.title }}</v-list-item-title>
                        </v-list-item>

                    </v-sheet>
                </v-bottom-sheet>

            </div>
        </template>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetProgetti" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Progetti
                            <v-btn class='closeSheet align_close_sheet' icon @click="sheetProgetti = !sheetProgetti">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                    v-model="searchProgetti" id="fldTextSearchAzi">
                                </v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredProgetti" :key="item.projectid"
                                    @click="manageClickProgetti(item)">

                                    <v-img alt="" src="@/assets/progetti_emt.png" max-width="24" max-height="24" />

                                    <v-list-item-title class='itemAzienda'>{{ item.codice }}</v-list-item-title>

                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>



    </div>
</template>

<script>
var env = process.env.NODE_ENV;
if (env != "development") console.log = function () { };

import _ from 'lodash';


import datepicker from "v-calendar/lib/components/date-picker.umd";
import RiseLoader from "vue-spinner/src/RiseLoader.vue";
import {
    bus
} from "../main";

import apitimesheet from "../utils/timesheet/apitimesheet";
import router from ".././router";

export default {

    watch: {
        date: {
            handler: function () {
                // console.log(this.date)
            },
            deep: true,
        },
    },

    updated() {

    },

    mounted: async function () {

        /*eslint-disable no-undef*/



        this.$root.$children[0].checkLogout();

        var pointerVue = this;

        window.numEventOrdinario = 0;
        window.numEventASaveAndOpen = 0;
        window.numEventASaveAndClose = 0;
        window.numEventASave = 0;
        window.numEventPdfAmm = 0;
        window.numEventPdfFull = 0;

        window.numEventDipAvanti = 0;
        window.numEventDipIndietro = 0;

        window.curRowDip = 0;

        window.curProgetto = "";

        this.dipendenti = this.$root.$children[0].dipendenti;
        this.aziende = this.$root.$children[0].aziende;

        console.log("AZI 2: ", this.aziende);

        this.isDipEbadge = parseInt(window.$cookies.get("is_ebadge"));

        bus.$off("bottomIconClickEvent");




        setTimeout(() => {

            this.getIdProjectDefault();

        }, 100);


        bus.$on("menuClickEvent", (data) => {

            switch (data) {

                case "loadAziende":

                    break;

                case "showaziende":

                    this.sheetAziende = true;
                    break;
            }
        });

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {


                case "btn_status":
                    this.sheetStatus = true;

                    break;

                case "btn_refr_tms":
                    this.resetDay();
                    this.syncMonth();
                    break;
                case "btn_send_tms":

                    this.validateSendTimesheetForApprove();

                    break;


                case "btn_save":

                    this.validateSave();

                    break;

                case "btn_en_dis_interv":

                    this.enableViewColumns();

                    setTimeout(() => {

                        pointerVue.colorRows();

                    }, 200);

                    break;

                case "btn_delete":


                    this.deleteRow();



                    break;

                case "btn_copy":

                    this.copyRow();

                    break;

                case "btn_go_disease":

                    this.goToDisease();

                    break;

                case "btn_go_ferie":

                    this.goToHoliday();

                    break;


                default:
                    break;
            }
        });



        $(document).ready(function () {

            $('body').on('click', '.selprogetti', function () {


                pointerVue.sheetProgetti = true;


            });



            $('body').on('click', '#btn_ferie_permessi', function () {


                pointerVue.getTotalsHolidaysPermits();

            });



            $('body').on('click', '.deleteRow', function () {

                pointerVue.deleteRowTs($(this).attr("v_login"), $(this).attr("v_day"), $(this).attr("v_month"), $(this).attr("v_year"));


            });


        });



        setTimeout(() => {

            pointerVue.getProgetti();

        }, 1000);



        this.setupGrid();



        // setTimeout(() => {


        //     if (window.innerWidth <= 768) {

        //         $(".clStatusTS").css("transform", "translate(171px, 91px)");

        //     }
        //     else {

        //         $(".clStatusTS").css("transform", "translate(160px, 101px)");

        //     }


        // }, 500);



    },

    mountedOLD() {
        //console.log ( "CAlendar" ,this.$refs.defCalendar );
        //var pointerTHis = this ;

        $("body").on("click", ".v-menu__content", function () {

            //$(this).hide();
            //  $(".selectHour").trigger("blur");
            //      window.pointerThis = pointerTHis ;

            //this.$refs.comboOrdinari.click();
            //      console.log ( "all refs" , window.refs );
            //$(".v-select--is-menu-active").find(".v-icon").trigger("click");

            /* $(".v-select--is-menu-active").find(".v-select__slot").trigger("blur");

            setTimeout(() => {
                $("body").trigger("click");

            }, 100); */

            //$(".v-select__slot").trigger("click");
            //window.refs.comboOrdinari.hide();

            //   this.$refs.combo.blur();

        });

    },
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];
        this.$root.$children[0].buttonsTop = [];

        //to manage if admin
        var namesurname = window.$cookies.get("namesurname");

        this.$root.$children[0].curDipTop = namesurname;
        this.$root.$children[0].curDipOreTop = "";
        this.$root.$children[0].curDipOreTopLabel = "";

        bus.$off("bottomIconClickEvent");
        bus.$off("menuClickEvent");
    },



    methods: {

        getProgetti: async function () {

            console.log("GETPROGETTI: ", window.user_selected);


            var that = this;

            var sel_login_ver_ts = window.user_selected;



            var response = await apitimesheet.getProgetti(

                sel_login_ver_ts

            ).then((res) => {


                console.log("res from getProgetti", res);


                that.progetti = res.data.Result;




            }).catch(err => {

                that.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        manageClickProgetti: async function (progetto) {

            this.sheetProgetti = false;

            console.log("PROGETTO: ", progetto);

            window.curProgetto = progetto.projectid;

            //window.table.ajax.reload();

            this.syncMonth();




        },

        goToHoliday: function () {

            window.$cookies.set("is_from_ts_user", 1, "9y");

            router.push({
                path: "/initHoliday"
            });


        },

        goToDisease: function () {

            window.$cookies.set("is_from_ts_user", 1, "9y");

            router.push({
                path: "/initDisease"
            });


        },

        copyRow: async function () {

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });


            console.log("IDS: ", ids);



            that.showSpinner = true;

            var response = await apitimesheet.copyRow(
                ids
            ).then((res) => {

                that.showSpinner = false;

                console.log("res from copyRow", res);

                that.$swal({
                    icon: "success",
                    text: "Dati copiati con successo",
                    showConfirmButton: false,
                    timer: 2000
                });

                window.numEventASave = 0;

                that.syncMonth();

            }).catch(err => {

                that.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        deleteRow: async function () {

            var that = this;

            var str_values = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (str_values != "") {
                        str_values = str_values + "#";
                    }

                    var v_login = $(this).attr("v_login");
                    var v_day = $(this).attr("v_day");
                    var v_month = $(this).attr("v_month");
                    var v_year = $(this).attr("v_year");
                    var v_ordine = $(this).attr("v_ordine");

                    //this.deleteRowTs(v_login, v_day, v_month, v_year, v_ordine);

                    str_values = str_values + v_login + "|" + v_day + "|" + v_month + "|" + v_year + "|" + v_ordine;

                }

            });


            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare gli elementi selezionati ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {


                        console.log("VALUES: ", str_values);



                        that.showSpinner = true;

                        var response = await apitimesheet.deleteRowTsMultiNew(
                            str_values
                        ).then((res) => {

                            that.showSpinner = false;

                            console.log("res from deleteRowTsMultiNew", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati con successo",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            window.numEventASave = 0;

                            that.syncMonth();

                        }).catch(err => {

                            that.showSpinner = false;

                            window.numEventASaveAndOpen = 0;

                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );




                    }





                });


        },


        formatDateTime: function (v_datetime) {

            if (v_datetime != "") {

                if (v_datetime.toString().indexOf("T") !== -1) {


                    v_datetime = v_datetime.toString().substring(11, 16);
                    v_datetime = v_datetime + ":00";


                }

            }

            return v_datetime;

        },


        hideShowButtonsSelRow: function () {


            console.log("STATUS: ", this.curStatus);


            var tot = 0;
            var n_prog = 0;
            var tot_day_ass = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    n_prog = $(this).attr("v_prog_assoc");

                    var v_idtype_activity = $(this).attr("v-idtypeactivity");

                    if (v_idtype_activity > 0) {
                        tot_day_ass = tot_day_ass + 1;
                    }


                }

            });

            setTimeout(() => {

                var pulsantis = [];

                if (this.curStatus == "Open") {

                    pulsantis.push(

                        {
                            text: "Ricarica",
                            icon: "mdi-refresh",
                            image: "https://app.emtool.eu/public/_lib/img/refresh-emt.png",
                            link: "",
                            id: "btn_refr_tms",
                            disabled: false,
                            title: "Aggiorna",
                            width: 30
                        },



                    );

                    pulsantis.push(



                        {
                            text: "",
                            icon: "mdi-refresh",
                            image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                            link: "",
                            id: "btn_save",
                            disabled: false,
                            title: "Salva",
                            width: 28
                        },



                    );


                    pulsantis.push(



                        {
                            text: "",
                            icon: "mdi-refresh",
                            image: "https://app.emtool.eu/public/_lib/img/cambio-stato-emt.png",
                            link: "",
                            id: "btn_send_tms",
                            disabled: false,
                            title: "Invia Per Approvazione",
                            width: 35
                        },



                    );


                    if (tot > 0) {

                        if (tot_day_ass == 0) {

                            pulsantis.push(

                                {
                                    text: "Delete",
                                    icon: "mdi-home-circle",
                                    image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                                    link: "/info",
                                    id: "btn_delete",
                                    disabled: false,
                                    title: "Elimina",
                                    width: 30
                                },



                            );

                        }




                    }

                    if (tot > 0) {


                        if (n_prog > 1) {

                            pulsantis.push(




                                {
                                    text: "",
                                    icon: "mdi-refresh",
                                    image: this.getUrlDomain("public/_lib/img/copia-emt.png"),
                                    link: "",
                                    id: "btn_copy",
                                    disabled: false,
                                    title: "Copia",
                                    width: 35
                                },



                            );




                        }



                    }

                    pulsantis.push(



                        {
                            text: "",
                            icon: "mdi-refresh",
                            image: this.getUrlDomain("public/_lib/img/enable_disable_buttons_emt.png"),
                            link: "",
                            id: "btn_en_dis_interv",
                            disabled: false,
                            title: "Abilita / Disabilita Intervento",
                            width: 35
                        },



                    );


                    var is_partita_iva = window.$cookies.get("is_partita_iva");

                    if (is_partita_iva == 0) {

                        pulsantis.push(



                            {
                                text: "",
                                icon: "mdi-refresh",
                                image: this.getUrlDomain("public/_lib/img/malattia4.png"),
                                link: "",
                                id: "btn_go_disease",
                                disabled: false,
                                title: "Malattia",
                                width: 30
                            },



                        );

                        pulsantis.push(



                            {
                                text: "",
                                icon: "mdi-refresh",
                                image: this.getUrlDomain("public/_lib/img/ferie2.png"),
                                link: "",
                                id: "btn_go_ferie",
                                disabled: false,
                                title: "Ferie / Permessi",
                                width: 30
                            },



                        );


                    }






                }


                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);



        },

        viewColumns: function (v_enable) {
            window.table.column(10).visible(v_enable);
            window.table.column(11).visible(v_enable);
            window.table.column(12).visible(v_enable);
        },


        enableViewColumns: function () {

            var that = this;

            if (that.showColumns == true) {
                window.table.column(10).visible(false);
                window.table.column(11).visible(false);
                window.table.column(12).visible(false);
                that.showColumns = false;
            }
            else {

                window.table.column(10).visible(true);
                window.table.column(11).visible(true);
                window.table.column(12).visible(true);
                that.showColumns = true;


            }


        },

        updateTotalRows: function () {

            var dataTableElements = window.table.data();

            console.log("DATA TOTAL ROWS: ", dataTableElements);

            for (var xDay = 0; xDay < dataTableElements.length; xDay++) {

                var vRowDay = dataTableElements[xDay];

                var total = parseFloat(vRowDay.hour_ordinario) + parseFloat(vRowDay.hour_activity);

                var keyTotal = "#sel_ore_totali_" + vRowDay.day.toString();

                $(keyTotal).html(total.toFixed(2));


            }



        },


        colorRows: function () {

            var dataTableElements = window.table.data();

            var numCol = 14;

            for (var xDay = 0; xDay < dataTableElements.length; xDay++) {


                console.log("ELEMENTS TR2: ", dataTableElements[xDay]);

                var vRowDay = dataTableElements[xDay];

                if (vRowDay.desc_day != "Dom") {

                    if (vRowDay.desc_day != "Sab") {


                        if (vRowDay.fest_gen == 1) {

                            for (var xCol3 = 0; xCol3 <= numCol; xCol3++) {


                                $("#myTable > tbody > tr").eq(xDay).find("td").eq(xCol3).addClass("clRowFest");

                            }


                        }


                        if (vRowDay.fest_gen != 1) {

                            if (vRowDay.idtypeactivity == 4) {

                                for (var xCol4 = 0; xCol4 <= numCol; xCol4++) {


                                    $("#myTable > tbody > tr").eq(xDay).find("td").eq(xCol4).addClass("clRowMalattia");

                                }


                            }


                            if (vRowDay.idtypeactivity != 4) {

                                if (vRowDay.hour_ordinario > 0) {


                                    if (vRowDay.hour_activity == 0) {



                                        for (var xCol = 0; xCol <= numCol; xCol++) {


                                            $("#myTable > tbody > tr").eq(xDay).find("td").eq(xCol).addClass("clRowGreen");

                                        }




                                    }



                                }

                                if (vRowDay.hour_ordinario == 0) {


                                    if (vRowDay.hour_activity > 0) {



                                        for (var xCol2 = 0; xCol2 <= numCol; xCol2++) {


                                            $("#myTable > tbody > tr").eq(xDay).find("td").eq(xCol2).addClass("clRowYellow");

                                        }


                                    }



                                }




                                if (vRowDay.hour_ordinario > 0) {


                                    if (vRowDay.hour_activity > 0) {



                                        for (var xCol5 = 0; xCol5 <= numCol; xCol5++) {


                                            $("#myTable > tbody > tr").eq(xDay).find("td").eq(xCol5).addClass("clRowYellow");

                                        }


                                    }



                                }



                            }









                        }

                    }


                }




            }


        },


        generateRandomString: function (length, characters) {

            let result = "";
            const charLength = characters.length;

            for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * charLength);
                result += characters.charAt(randomIndex);
            }

            return result;


        },


        deleteRowTs: async function (v_login, v_day, v_month, v_year) {

            var that = this;

            console.log("LOGIN: ", v_login);
            console.log("DAY: ", v_day);
            console.log("MONTH: ", v_month);
            console.log("YEAR: ", v_year);


            var strDay = v_day;
            if (strDay.length == 1) {
                strDay = "0" + strDay.toString();
            }

            var strMonth = v_month;
            if (strMonth.length == 1) {
                strMonth = "0" + strMonth.toString();
            }

            var strDate = strDay + "/" + strMonth + "/" + v_year.toString();

            var strMsg = "Vuoi eliminare la giornata del " + strDate + " ?";

            that.$swal
                .fire({
                    title: "Elimina",
                    html: strMsg,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {



                        that.showSpinner = true;

                        var response = await apitimesheet.deleteRowTs(
                            v_login,
                            v_day,
                            v_month,
                            v_year
                        ).then((res) => {

                            that.showSpinner = false;

                            console.log("res from saveData", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati con successo",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            window.numEventASave = 0;

                            that.syncMonth();

                        }).catch(err => {

                            that.showSpinner = false;

                            window.numEventASaveAndOpen = 0;

                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );





                    }





                });


        },


        sendTimesheetForApprove: async function () {

            var that = this;

            var $mesi = [
                "",
                "gennaio",
                "febbraio",
                "marzo",
                "aprile",
                "maggio",
                "giugno",
                "luglio",
                "agosto",
                "settembre",
                "ottobre",
                "novembre",
                "dicembre",
            ];
            var text = $mesi[that.curMonth] + " - " + that.curYear;

            console.log("this.curStatus", that.curStatus)
            var statonum = 1;
            // (1 - Aperto 2 - In Approvazione 3 - Chiuso) (obbligatorio)

            if (that.curStatus == 'Open') statonum = 1;
            if (that.curStatus == 'Close') statonum = 3;
            if (that.curStatus == 'For approval') statonum = 2;

            if (that.aa == 'Y' || that.su == 'Y') {

                console.log(statonum);

                that.sheetStatus = true;

            } else {
                that.$swal
                    .fire({
                        title: "Sei sicuro?",
                        html: "Vuoi inviare il timesheet <h4>" + text + " ?</h4> ",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                        cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                        customClass: {
                            cancelButton: 'order-1',
                            confirmButton: 'order-2',
                        }

                    })
                    .then((result) => {
                        if (result.value == true) {

                            that.saveDataForAppr();

                        }
                    });
            }

        },


        validateSendTimesheetForApprove: function () {

            var that = this;


            var params = that.getParamsApi();

            console.log("SAVE_ADN_OPEN");
            console.log("CUR_YEAR: ", that.curYear);
            console.log("CUR_MONTH: ", that.curMonth);
            console.log("USR_SEL: ", atob(params.token));

            var data_timesheet = [];

            $(".fldchanged").each(function (index) {

                console.log(index + ": " + $(this).text());

                console.log("VAL: ", $(this).val());

                var v_row = $(this).parent().parent();

                console.log("V_ROW: ", v_row);
                var item = window.table.row(v_row).data();

                //var v_day = index + 1; // valore giorno
                var v_day = item.day; // valore giorno
                var v_forz = "N";
                var v_note = $("#sel_desc_" + v_day + "_" + item.ordine).val();
                var v_h_ord = $("#sel_ordinario_" + v_day + "_" + item.ordine).val();
                var v_h_straord = $("#sel_eccedenze_" + v_day + "_" + item.ordine).val();
                var v_h_act = $("#sel_ore_ass_" + v_day + "_" + item.ordine).val();
                var v_id_type_act = $("#sel_tipo_ass_" + v_day + "_" + item.ordine).val();
                var v_h_act2 = $("#sel_ore_ass_2_" + v_day + "_" + item.ordine).val();
                var v_id_type_act2 = $("#sel_tipo_ass_2_" + v_day + "_" + item.ordine).val();

                var v_id_prj = that.idProjDefault;

                if (that.countProjAssoc > 1) {

                    v_id_prj = $("#sel_prj_" + v_day + "_" + item.ordine).val();

                }


                var v_desc_day = $("#desc_day_" + v_day + "_" + item.ordine).html();

                var v_h_rep = $("#sel_reperibilita_" + v_day + "_" + item.ordine).val();

                var v_h_int = 0;
                var v_h_from_int = "";
                var v_h_to_int = "";

                if (that.showColumns == true) {

                    v_h_int = $("#sel_intervento_" + v_day + "_" + item.ordine).val();
                    v_h_from_int = $("#sel_ora_da_intervento_" + v_day + "_" + item.ordine).val();
                    v_h_to_int = $("#sel_ora_a_intervento_" + v_day + "_" + item.ordine).val();

                }


                var check_sede_cliente = 0;
                if ($("#sel_sede_cliente_" + v_day + "_" + item.ordine).prop("checked")) {
                    check_sede_cliente = 1;
                }


                console.log("VAL GIORNO: ", v_day);
                console.log("VAL FORZATURA: ", v_forz);

                data_timesheet.push({
                    day_modified: $(this).val(),
                    forzatura: v_forz,
                    note: v_note,
                    year: that.curYear,
                    month: that.curMonth,
                    day: v_day,
                    hour_ordinario: v_h_ord,
                    hour_straordinario: v_h_straord,
                    hour_activity: v_h_act,
                    idtypeactivity: v_id_type_act,
                    hour_activity2: v_h_act2,
                    idtypeactivity2: v_id_type_act2,
                    projectid: v_id_prj,
                    desc_day: v_desc_day,
                    is_fest: $(this).attr("is-fest"),
                    hour_reperibilita: v_h_rep,
                    hour_intervento: v_h_int,
                    hour_from_intervento: that.formatDateTime(v_h_from_int),
                    hour_to_intervento: that.formatDateTime(v_h_to_int),
                    ordine: item.ordine,
                    sede_cliente: check_sede_cliente
                });

            });

            console.log("DATA TS: ", data_timesheet);


            var errore = "";

            if (that.countProjAssoc < 2) {

                for (var x = 0; x <= data_timesheet.length - 1; x++) {

                    var giorno = data_timesheet[x];


                    if ((giorno.desc_day.indexOf("Sab") == -1) && (giorno.desc_day.indexOf("Dom") == -1)) {

                        if (parseInt(giorno.idtypeactivity) != 8)  // diverso da non in forza
                        {



                            if (errore == "") {

                                if (parseFloat(giorno.hour_activity) > 0) // sono presenti delle ore di assenza nella gionata
                                {

                                    if (parseInt(giorno.idtypeactivity) == 0) // non è stata specificata la causale
                                    {

                                        errore = "Specificare una causale per le ore di assenza del giorno " + giorno.day;

                                    }


                                }


                            }


                            if (errore == "") {

                                if (parseInt(giorno.idtypeactivity) > 0) // è stata specificata la causale
                                {


                                    if (parseInt(giorno.idtypeactivity) != 5) {

                                        if (parseFloat(giorno.hour_activity) == 0) // non sono presenti delle ore di assenza nella gionata
                                        {

                                            errore = "Specificare le ore di assenza per il giorno " + giorno.day;


                                        }



                                    }




                                }

                            }


                            if (errore == "") {

                                if (giorno.hour_straordinario == "") {

                                    errore = "Specificare un valore per lo straordinario per il giorno " + giorno.day;


                                }


                            }

                            if (that.IsPartiIVAPartTime != "S") {

                                if (errore == "") {

                                    if ((parseFloat(giorno.hour_ordinario) == 0) && (parseFloat(giorno.hour_activity) == 0)) {

                                        errore = "Specificare un valore per le ore di assenza per il giorno " + giorno.day;


                                    }

                                }

                                if (errore == "") {

                                    var v_tot = parseFloat(giorno.hour_ordinario) + parseFloat(giorno.hour_activity);

                                    if (v_tot != 8) {

                                        errore = "La somma delle ore ordinarie e delle assenza deve essere di 8 ore per il giorno " + giorno.day;


                                    }

                                }

                            }






                            if (errore == "") {

                                var h_ord = parseFloat(giorno.hour_ordinario);
                                var h_straord = parseFloat(giorno.hour_straordinario);
                                var h_ass = parseFloat(giorno.hour_activity);

                                if ((h_ord == 0) && (h_straord > 0) && (h_ass > 0)) {

                                    errore = "Specificare delle ore ordinarie per il giorno " + giorno.day;


                                }

                            }


                            if (errore == "") {

                                var h_ord2 = parseFloat(giorno.hour_ordinario);
                                var h_straord2 = parseFloat(giorno.hour_straordinario);
                                var h_ass2 = parseFloat(giorno.hour_activity);

                                if ((h_ord2 == 0) && (h_straord2 > 0) && (h_ass2 > 0)) {

                                    errore = "Specificare delle ore ordinarie per il giorno " + giorno.day;


                                }

                            }

                            if (errore == "") {

                                var h_straord3 = parseFloat(giorno.hour_straordinario);

                                if (h_straord3 > 10) {

                                    errore = "Le ore di straordinario devono essere al massimo 10 per il giorno " + giorno.day;


                                }

                            }






                        }




                    }









                }

            }




            if (errore == "") {


                console.log("ok");


                that.sendTimesheetForApprove();

            }
            else {

                window.numEventASave = 0;

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                });


            }


        },



        validateSave: function () {

            var that = this;


            var params = that.getParamsApi();

            console.log("SAVE_ADN_OPEN");
            console.log("CUR_YEAR: ", that.curYear);
            console.log("CUR_MONTH: ", that.curMonth);
            console.log("USR_SEL: ", atob(params.token));

            var data_timesheet = [];

            $(".fldchanged").each(function (index) {

                console.log(index + ": " + $(this).text());

                var v_row = $(this).parent().parent();

                console.log("V_ROW: ", v_row);
                var item = window.table.row(v_row).data();

                console.log("ROM ITEM: ", item);
                console.log("ROM ITEM ORDINE: ", item.ordine);


                console.log("VAL: ", $(this).val());


                console.log("SEL TIPO ASS: ", $("#sel_tipo_ass_" + v_day + "_" + item.ordine));


                var v_day = item.day; // valore giorno

                var v_forz = "N";
                var v_note = $("#sel_desc_" + v_day + "_" + item.ordine).val()
                var v_h_ord = $("#sel_ordinario_" + v_day + "_" + item.ordine).val();
                var v_h_straord = $("#sel_eccedenze_" + v_day + "_" + item.ordine).val();
                var v_h_act = $("#sel_ore_ass_" + v_day + "_" + item.ordine).val();
                var v_id_type_act = $("#sel_tipo_ass_" + v_day + "_" + item.ordine).val();
                var v_h_act2 = $("#sel_ore_ass_2_" + v_day + "_" + item.ordine).val();
                var v_id_type_act2 = $("#sel_tipo_ass_2_" + v_day + "_" + item.ordine).val();
                var v_totale = $("#sel_ore_totali_" + v_day + "_" + item.ordine).html();


                var v_id_prj = that.idProjDefault;

                if (that.countProjAssoc > 1) {

                    v_id_prj = $("#sel_prj_" + v_day + "_" + item.ordine).val();

                }



                var v_desc_day = $("#desc_day_" + v_day + "_" + item.ordine).html();
                var v_h_rep = $("#sel_reperibilita_" + v_day + "_" + item.ordine).val();

                var v_h_int = 0;
                var v_h_from_int = "";
                var v_h_to_int = "";

                if (that.showColumns == true) {

                    v_h_int = $("#sel_intervento_" + v_day + "_" + item.ordine).val();
                    v_h_from_int = $("#sel_ora_da_intervento_" + v_day + "_" + item.ordine).val();
                    v_h_to_int = $("#sel_ora_a_intervento_" + v_day + "_" + item.ordine).val();

                }

                console.log("ORA FROM: ", v_h_from_int);
                console.log("ORA TO: ", v_h_to_int);

                console.log("VAL GIORNO: ", v_day);
                console.log("VAL FORZATURA: ", v_forz);


                var check_sede_cliente = 0;
                if ($("#sel_sede_cliente_" + v_day + "_" + item.ordine).prop("checked")) {
                    check_sede_cliente = 1;
                }



                data_timesheet.push({
                    day_modified: $(this).val(),
                    forzatura: v_forz,
                    note: v_note,
                    year: that.curYear,
                    month: that.curMonth,
                    day: v_day,
                    hour_ordinario: v_h_ord,
                    hour_straordinario: v_h_straord,
                    hour_activity: v_h_act,
                    idtypeactivity: v_id_type_act,
                    hour_activity2: v_h_act2,
                    idtypeactivity2: v_id_type_act2,
                    projectid: v_id_prj,
                    desc_day: v_desc_day,
                    is_fest: $(this).attr("is-fest"),
                    hour_reperibilita: v_h_rep,
                    hour_intervento: v_h_int,
                    hour_from_intervento: that.formatDateTime(v_h_from_int),
                    hour_to_intervento: that.formatDateTime(v_h_to_int),
                    ordine: item.ordine,
                    sede_cliente: check_sede_cliente,
                    ore_totali: v_totale
                });

            });

            console.log("DATA TS: ", data_timesheet);


            var errore = "";

            if (that.countProjAssoc < 2) {

                for (var x = 0; x <= data_timesheet.length - 1; x++) {

                    var giorno = data_timesheet[x];


                    if ((giorno.desc_day.indexOf("Sab") == -1) && (giorno.desc_day.indexOf("Dom") == -1)) {

                        if (parseInt(giorno.idtypeactivity) != 8)  // diverso da non in forza
                        {

                            if (errore == "") {

                                if (parseFloat(giorno.hour_activity) > 0) // sono presenti delle ore di assenza nella gionata
                                {

                                    if (parseInt(giorno.idtypeactivity) == 0) // non è stata specificata la causale
                                    {

                                        errore = "Specificare una causale per le ore di assenza del giorno " + giorno.day;

                                    }


                                }


                            }


                            if (errore == "") {

                                if (parseInt(giorno.idtypeactivity) > 0) // è stata specificata la causale
                                {

                                    if (parseFloat(giorno.hour_activity) == 0) // non sono presenti delle ore di assenza nella gionata
                                    {

                                        errore = "Specificare le ore di assenza per il giorno " + giorno.day;


                                    }



                                }

                            }



                            if (errore == "") {

                                if (parseInt(giorno.is_fest) == 1) // giorno festivo
                                {


                                    if (parseInt(giorno.day_modified) == 1) {


                                        if (parseFloat(giorno.hour_straordinario) == 0) {

                                            if (giorno.idtypeactivity != 5) {

                                                errore = "Non è possibile specificare un'assenza diversa dal festivo per il giorno " + giorno.day;



                                            }



                                        }


                                    }




                                }



                            }


                            if (errore == "") {

                                if (parseFloat(giorno.hour_reperibilita) == 0) {


                                    if (parseFloat(giorno.hour_intervento) > 0) {


                                        errore = "Specificare la reperibilità per il giorno " + giorno.day;


                                    }


                                }

                            }

                            if (errore == "") {

                                if (parseFloat(giorno.hour_reperibilita) > 0) {


                                    if (parseFloat(giorno.hour_intervento) > 0) {


                                        if (parseFloat(giorno.hour_reperibilita) < parseFloat(giorno.hour_intervento)) {

                                            errore = "La reperibilità deve essere maggiore o uguale dell'intervento per il giorno " + giorno.day;

                                        }




                                    }


                                }


                            }


                            if (errore == "") {

                                if (parseFloat(giorno.hour_intervento) > 0) {

                                    if ((giorno.hour_from_intervento == "") || (giorno.hour_to_intervento == "")) {

                                        errore = "Specificare Ora Da e Ora A dell'intervento per il giorno " + giorno.day;

                                    }

                                }


                            }


                            if (errore == "") {

                                if (parseFloat(giorno.hour_intervento) > 0) {

                                    var date1 = "2023-01-01 " + giorno.hour_from_intervento;
                                    var date2 = "2023-01-01 " + giorno.hour_to_intervento;

                                    date1 = new Date(date1);
                                    date2 = new Date(date2);

                                    if (date2 < date1) {

                                        errore = "Il valore di Ora A deve essere maggiore di Ora Da per il giorno " + giorno.day;


                                    }



                                }


                            }


                            if (errore == "") {

                                if ((giorno.hour_from_intervento != "") && (giorno.hour_to_intervento != "")) {

                                    if ((giorno.hour_from_intervento != "<nil>") && (giorno.hour_to_intervento != "<nil>")) {

                                        if (parseFloat(giorno.hour_intervento) == 0) {

                                            errore = "Specificare il totale ore intervento per il giorno " + giorno.day;


                                        }


                                    }



                                }


                            }




                            if (errore == "") {

                                if (giorno.hour_straordinario == "") {

                                    errore = "Specificare un valore per lo straordinario per il giorno " + giorno.day;


                                }


                            }

                            if (errore == "") {

                                if (giorno.hour_activity == "") {

                                    errore = "Specificare un valore per le ore di assenza per il giorno " + giorno.day;


                                }

                            }


                            if (errore == "") {

                                if ((parseFloat(giorno.hour_ordinario) == 0) && (parseFloat(giorno.hour_activity) == 0)) {

                                    errore = "Specificare un valore per le ore di assenza per il giorno " + giorno.day;


                                }

                            }

                            if (errore == "") {

                                var v_tot_day = parseFloat(giorno.ore_totali);

                                var v_tot = parseFloat(giorno.hour_ordinario) + parseFloat(giorno.hour_activity);

                                if (v_tot != v_tot_day) {

                                    errore = "La somma delle ore ordinarie e delle assenza deve essere di " + v_tot_day + " ore per il giorno " + giorno.day;


                                }

                            }

                            if (errore == "") {

                                var h_ord = parseFloat(giorno.hour_ordinario);
                                var h_straord = parseFloat(giorno.hour_straordinario);
                                var h_ass = parseFloat(giorno.hour_activity);

                                if ((h_ord == 0) && (h_straord > 0) && (h_ass > 0)) {

                                    errore = "Specificare delle ore ordinarie per il giorno " + giorno.day;


                                }

                            }


                            if (errore == "") {

                                var h_ord2 = parseFloat(giorno.hour_ordinario);
                                var h_straord2 = parseFloat(giorno.hour_straordinario);
                                var h_ass2 = parseFloat(giorno.hour_activity);

                                if ((h_ord2 == 0) && (h_straord2 > 0) && (h_ass2 > 0)) {

                                    errore = "Specificare delle ore ordinarie per il giorno " + giorno.day;


                                }

                            }


                            if (errore == "") {

                                var h_straord3 = parseFloat(giorno.hour_straordinario);

                                if (h_straord3 > 10) {

                                    errore = "Le ore di straordinario devono essere al massimo 10 per il giorno " + giorno.day;


                                }

                            }




                        }




                    }

                }


            }




            if (errore == "") {

                console.log("ok");

                that.saveData();

            }
            else {

                window.numEventASave = 0;

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                });


            }


        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },


        getIdProjectDefault: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            that.showSpinner = true;

            var response = await apitimesheet.goGetIdProgettoDefault(
                v_token,
                that.curYear,
                that.curMonth
            ).then((res) => {

                that.showSpinner = false;

                console.log("res from goGetIdProgettoDefault", res);

                that.idProjDefault = res.data.Result;

                that.IsPartiIVAPartTime = res.data.IsPartiIVAPartTime;

                that.countProjAssoc = res.data.CntProj;


                that.showColumns = false;

                if (res.data.ViewColInterv == 1) {
                    that.showColumns = true;

                }

                if (that.countProjAssoc < 2) {


                    $('.selprogetti').hide();



                }



            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },

        saveData: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var params = that.getParamsApi();

            console.log("SAVE_ADN_OPEN");
            console.log("CUR_YEAR: ", that.curYear);
            console.log("CUR_MONTH: ", that.curMonth);
            console.log("USR_SEL: ", atob(params.token));

            var data_timesheet = [];

            $(".fldchanged").each(function (index) {

                console.log(index + ": " + $(this).text());

                var v_row = $(this).parent().parent();

                console.log("V_ROW: ", v_row);
                var item = window.table.row(v_row).data();

                console.log(index + ": " + $(this).text());

                console.log("VAL: ", $(this).val());

                var v_day = item.day;

                var v_forz = "N";
                var v_note = $("#sel_desc_" + v_day + "_" + item.ordine).val();
                var v_h_ord = $("#sel_ordinario_" + v_day + "_" + item.ordine).val();
                var v_h_straord = $("#sel_eccedenze_" + v_day + "_" + item.ordine).val();
                var v_h_act = $("#sel_ore_ass_" + v_day + "_" + item.ordine).val();
                var v_id_type_act = $("#sel_tipo_ass_" + v_day + "_" + item.ordine).val();


                var v_id_prj = that.idProjDefault;

                if (that.countProjAssoc > 1) {

                    v_id_prj = $("#sel_prj_" + v_day + "_" + item.ordine).val();

                }

                var v_h_rep = $("#sel_reperibilita_" + v_day + "_" + item.ordine).val();

                var v_h_int = 0;
                var v_h_from_int = "";
                var v_h_to_int = "";

                var v_show_cols = 0;

                if (that.showColumns == true) {

                    v_h_int = $("#sel_intervento_" + v_day + "_" + item.ordine).val();
                    v_h_from_int = $("#sel_ora_da_intervento_" + v_day + "_" + item.ordine).val();
                    v_h_to_int = $("#sel_ora_a_intervento_" + v_day + "_" + item.ordine).val();

                    v_show_cols = 1;
                }




                console.log("VAL GIORNO: ", v_day);
                console.log("VAL FORZATURA: ", v_forz);

                var dataSpecifica = new Date(that.curYear, that.curMonth - 1, v_day);
                var opzioniNomeGiorno = { weekday: 'long' };
                var nomeGiorno = dataSpecifica.toLocaleDateString('it-IT', opzioniNomeGiorno);

                console.log("NOME GIORNO: ", nomeGiorno);


                if ((nomeGiorno == "sabato") || (nomeGiorno == "domenica")) {
                    v_id_prj = 0;

                }

                var check_sede_cliente = 0;
                if ($("#sel_sede_cliente_" + v_day + "_" + item.ordine).prop("checked")) {
                    check_sede_cliente = 1;
                }

                data_timesheet.push({
                    day_modified: $(this).val(),
                    forzatura: v_forz,
                    note: v_note,
                    year: that.curYear,
                    month: that.curMonth,
                    day: v_day,
                    hour_ordinario: v_h_ord,
                    hour_straordinario: v_h_straord,
                    hour_activity: v_h_act,
                    idtypeactivity: v_id_type_act,
                    hour_activity2: 0,
                    idtypeactivity2: 0,
                    projectid: v_id_prj,
                    hour_reperibilita: v_h_rep,
                    hour_intervento: v_h_int,
                    hour_from_intervento: that.formatDateTime(v_h_from_int),
                    hour_to_intervento: that.formatDateTime(v_h_to_int),
                    ordine: item.ordine,
                    show_cols: v_show_cols,
                    sede_cliente: check_sede_cliente
                });

            });

            console.log("DATA TS SAVE: ", data_timesheet);

            this.showSpinner = true;

            // var v_key = this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");
            // v_key = v_key + "|" + that.curYear.toString();
            // v_key = v_key + "|" + that.curMonth.toString();
            // v_key = v_key + "|" + atob(params.token);
            // v_key = v_key + "|" + JSON.stringify(data_timesheet);
            // v_key = v_key + "|" + v_token;
            // v_key = v_key + "|" + this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");
            // v_key = btoa(v_key);
            // v_key = this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6") + v_key + this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");


            var v_key = that.curYear.toString();
            v_key = v_key + "|" + that.curMonth.toString();
            v_key = v_key + "|" + atob(params.token);
            v_key = v_key + "|" + JSON.stringify(data_timesheet);
            v_key = v_key + "|" + v_token;


            var response = await apitimesheet.goSaveDataNew(

                v_key

            ).then((res) => {

                this.showSpinner = false;

                console.log("res from goSaveDataNew", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati con successo",
                    showConfirmButton: false,
                    timer: 2000
                });

                window.numEventASave = 0;

                this.syncMonth();

            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },

        saveDataForAppr: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var params = that.getParamsApi();

            console.log("SAVE_ADN_OPEN");
            console.log("CUR_YEAR: ", that.curYear);
            console.log("CUR_MONTH: ", that.curMonth);
            console.log("USR_SEL: ", atob(params.token));

            var data_timesheet = [];

            $(".fldchanged").each(function (index) {

                console.log(index + ": " + $(this).text());

                console.log("VAL: ", $(this).val());

                var v_row = $(this).parent().parent();

                console.log("V_ROW: ", v_row);
                var item = window.table.row(v_row).data();

                // var v_day = index + 1; // valore giorno
                var v_day = item.day;
                var v_forz = "N";
                var v_note = $("#sel_desc_" + v_day + "_" + item.ordine).val();
                var v_h_ord = $("#sel_ordinario_" + v_day + "_" + item.ordine).val();
                var v_h_straord = $("#sel_eccedenze_" + v_day + "_" + item.ordine).val();
                var v_h_act = $("#sel_ore_ass_" + v_day + "_" + item.ordine).val();
                var v_id_type_act = $("#sel_tipo_ass_" + v_day + "_" + item.ordine).val();

                var v_id_prj = that.idProjDefault;

                if (that.countProjAssoc > 1) {

                    v_id_prj = $("#sel_prj_" + v_day + "_" + item.ordine).val();

                }

                console.log("VAL GIORNO: ", v_day);
                console.log("VAL FORZATURA: ", v_forz);

                var dataSpecifica = new Date(that.curYear, that.curMonth - 1, v_day);
                var opzioniNomeGiorno = { weekday: 'long' };
                var nomeGiorno = dataSpecifica.toLocaleDateString('it-IT', opzioniNomeGiorno);

                console.log("NOME GIORNO: ", nomeGiorno);


                if ((nomeGiorno == "sabato") || (nomeGiorno == "domenica")) {
                    v_id_prj = 0;

                }

                var v_h_rep = $("#sel_reperibilita_" + v_day + "_" + item.ordine).val();

                var v_h_int = 0;
                var v_h_from_int = "";
                var v_h_to_int = "";

                var v_show_cols = 0;

                if (that.showColumns == true) {

                    v_h_int = $("#sel_intervento_" + v_day + "_" + item.ordine).val();
                    v_h_from_int = $("#sel_ora_da_intervento_" + v_day + "_" + item.ordine).val();
                    v_h_to_int = $("#sel_ora_a_intervento_" + v_day + "_" + item.ordine).val();

                    v_show_cols = 1;
                }

                var check_sede_cliente = 0;
                if ($("#sel_sede_cliente_" + v_day + "_" + item.ordine).prop("checked")) {
                    check_sede_cliente = 1;
                }

                data_timesheet.push({
                    day_modified: $(this).val(),
                    forzatura: v_forz,
                    note: v_note,
                    year: that.curYear,
                    month: that.curMonth,
                    day: v_day,
                    hour_ordinario: v_h_ord,
                    hour_straordinario: v_h_straord,
                    hour_activity: v_h_act,
                    idtypeactivity: v_id_type_act,
                    hour_activity2: 0,
                    idtypeactivity2: 0,
                    projectid: v_id_prj,
                    hour_reperibilita: v_h_rep,
                    hour_intervento: v_h_int,
                    hour_from_intervento: that.formatDateTime(v_h_from_int),
                    hour_to_intervento: that.formatDateTime(v_h_to_int),
                    ordine: item.ordine,
                    show_cols: v_show_cols,
                    sede_cliente: check_sede_cliente
                });

            });

            console.log("DATA TS: ", data_timesheet);

            this.showSpinner = true;

            var v_key = this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");
            v_key = v_key + "|" + that.curYear.toString();
            v_key = v_key + "|" + that.curMonth.toString();
            v_key = v_key + "|" + atob(params.token);
            v_key = v_key + "|" + JSON.stringify(data_timesheet);
            v_key = v_key + "|" + v_token;
            v_key = v_key + "|" + this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");
            v_key = btoa(v_key);
            v_key = this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6") + v_key + this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");


            var response = await apitimesheet.saveDataNew(

                v_key

            ).then(async (res) => {

                console.log(res);

                setTimeout(() => {

                    var response2 = that.updateStatusMonth(2).then((res2) => {

                        that.showSpinner = false;

                        if (res2.status == 200) {
                            // this.$swal("Il timesheet è stato inviato correttamente");
                            that.$swal({
                                icon: "success",
                                text: "Il timesheet è stato inviato correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });
                            that.syncMonth();
                        } else {
                            that.$swal({
                                icon: "error",
                                text: "Il timesheet non è stato inviato correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });
                        }
                    });

                    console.log("Response", response2);

                }, 1000);





            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },





        setFlagFldChanged: function (v_day) {

            console.log("FLAG CHANGED");

            $("#fldchanged_" + v_day).val("1");

        },

        setupGrid: function () {

            var pointerVue = this;

            var params = this.getParamsApi();

            console.log("SETUP_GRID");
            console.log("CUR_YEAR: ", pointerVue.curYear);
            console.log("CUR_MONTH: ", pointerVue.curMonth);
            console.log("TOKEN_USER: ", atob(params.token));
            console.log("CUR_FULL_NAME_RES: ", this.curResource);
            console.log("CUR_AZIENDA: ", this.currAzienda);

            window.anno = pointerVue.curYear;
            window.mese = pointerVue.curMonth;
            window.user_selected = atob(params.token);
            //window.cur_full_name_res = this.curResource;

            window.cur_full_name_res = this.currAzienda;


            var pulsanti = "";

            console.log(pulsanti);

            $(document).ready(function () {

                var v_token = window.$cookies.get("token");

                window.columns = [

                    {
                        data: 'sel_row',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },


                    {
                        data: 'descr_day',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '2%',
                    },



                    {
                        data: 'fld_ordinario',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '2%',
                    },

                    {
                        data: 'fld_eccedenze',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '2%',
                    },

                    {
                        data: 'fld_ore_assenza_1',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '2%',
                    },

                    {
                        data: 'fld_tipo_assenza_1',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '3%',
                    },

                    {
                        data: 'fld_ore_totali',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '3%',
                    },

                    {
                        data: 'fld_descrizione_1',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '3%',
                    },

                    {
                        data: 'sede_cliente',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '2%',
                    },

                    {
                        data: 'reperibilita_new',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '2%',
                    },

                    {
                        data: 'interv_new',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '2%',
                    },

                    {
                        data: 'interv_ora_da_new',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'interv_ora_a_new',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },





                    {
                        data: 'progetto_new',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '2%',
                    },

                    {
                        data: 'nota_spese_new',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '2%',
                    },


                    {
                        data: 'spazio',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                ];

                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": 31,

                    processing: false,
                    paging: false,
                    serverSide: false,
                    "aaSorting": [],

                    /*  pageSize: 30, */
                    "sDom": "lfrti",


                    data: window.dataset,

                    ajax: {

                        url: pointerVue.getUrlDomain("goEditTimesheet") + '?username=' + v_token,

                        data: function (valori) {

                            console.log("VALORI:", valori);

                            valori.anno = window.anno;
                            valori.mese = window.mese;
                            valori.user_selected = window.user_selected;

                            valori.curProgetto = window.curProgetto;

                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();


                        $(".downloadspinner").hide();



                        setTimeout(() => {

                            pointerVue.funcEvent();

                            pointerVue.colorRows();

                            pointerVue.updateTotalRows();

                            pointerVue.viewColumns(pointerVue.showColumns);


                        }, 300);









                    }

                });

                $('body').on('click', '.cont_ts_un_prog tr', function () {


                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            console.log("ROW DATA: ", row_data);


                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");


                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row");


                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row");



                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);


                            console.log("FIND VAL_STATUS: ", $(this).find(".val_status"));

                            var find_check = $(this).find(".val_status");
                            if (find_check.is(":checked")) {
                                console.log("SELEZIONATO");

                            }

                            if (!find_check.is(":checked")) {
                                console.log("DESELEZIONATO");

                            }


                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);




                });

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");


                $("#myTable_filter").prepend(`
                


                <div class='cont_names'>
                    <div id='full_name_res' class='name_res'></div>

                      <img src='/emt/img/progetti_emt.png' style='border:0!important;' class='selprogetti' title='Progetti' />

                        <div id='user_red' title='Aperto'><img src='/emt/img/user_red.png' style='border:0!important;width:30px;float:right;margin-top:-13px;margin-right:16px' alt='Aperto' /></div>

                    <div id='user_yellow'><img src='/emt/img/user_yellow.png' style='border:0!important;width:30px;float:right;margin-top:-13px;margin-right:16px' title='In Approvazione' /></div>


                    <div id='user_green'><img src='/emt/img/user_green.png' style='border:0!important;width:30px;float:right;margin-top:-13px;margin-right:16px' title='Chiuso' /></div>

                    <div id='sec_move_dip' class="move_dip" style=''>
                        
                        <div style="font-weight:bold">
                            <img id='btn_indietro_dip' src='/emt/img/left_arrow.png' style='border:0!important;height:28px;padding-top:5px;cursor:pointer;padding-right:11px' title='Indietro' />
                        </div>
                        <div style="font-weight:bold" id="name_dip_sel"></div>
                        <div style="font-weight:bold">
                            <img src='/emt/img/right_arrow.png' style='border:0!important;height:28px;padding-top:5px;cursor:pointer;padding-left:11px' title='Avanti' id='btn_avanti_dip' />
                        </div>

                    </div>
                </div>
            `);




            });

        },

        funcEvent: function () {

            var that = this;

            $(".selordinario").off();

            $(".selordinario").on("ionChange", function (opt) {

                console.log("NUM EVENT ORD: ", window.numEventOrdinario);

                if (window.numEventOrdinario == 0) {

                    // alert("evento")

                    console.log(opt);


                    //console.log("ID: ", $(this).attr("id"));

                    var val_data_hour_ordinario = $(this).attr("id");
                    var val_day = val_data_hour_ordinario.split("_")[2];
                    //var val_forzatura = $("#sel_forzatura_" + val_day).val();
                    // var val_forzatura = "N";

                    // var hour_ordinario = this.value;

                    window.numEventOrdinario = window.numEventOrdinario + 1;

                    // that.calcOvtAbs(val_day, val_forzatura, hour_ordinario);

                    that.setFlagFldChanged(val_day);

                }

            });

            $(".selforzatura").on("ionChange", function (opt) {

                console.log(opt);

                var val_data_hour_ordinario = $(this).attr("id");
                var val_day = val_data_hour_ordinario.split("_")[2];

                console.log("DAY: ", val_day);

                if (this.value == "S") {

                    $("#sel_eccedenze_" + val_day).removeAttr("readonly");
                    $("#sel_ore_ass_" + val_day).removeAttr("readonly");

                }

                if (this.value == "N") {

                    $("#sel_eccedenze_" + val_day).val("0.00");
                    $("#sel_eccedenze_" + val_day).attr('readonly', true);

                    $("#sel_ore_ass_" + val_day).val("0.00");
                    $("#sel_ore_ass_" + val_day).attr('readonly', true);

                }

                that.setFlagFldChanged(val_day);

            });



            $(".selstraordinario").on("ionBlur", function (opt) {

                console.log(opt);

                that.setFlagFldChanged(val_day);

            });

            $(".selassenza").on("ionBlur", function (opt) {

                console.log(opt);

                that.setFlagFldChanged(val_day);

            });


            $(".seltipoass1").on("ionChange", function (opt) {

                console.log(opt);

                var val_data_hour_ordinario = $(this).attr("id");
                var val_day = val_data_hour_ordinario.split("_")[3];

                that.setFlagFldChanged(val_day);

            });

            $(".oreass2").on("ionChange", function (opt) {

                console.log(opt);

                var val_data_hour_ordinario = $(this).attr("id");
                var val_day = val_data_hour_ordinario.split("_")[4];

                that.setFlagFldChanged(val_day);

            });

            $(".seltipoass2").on("ionChange", function (opt) {

                console.log(opt);

                var val_data_hour_ordinario = $(this).attr("id");
                var val_day = val_data_hour_ordinario.split("_")[3];

                that.setFlagFldChanged(val_day);

            });

            $(".seledesc").on("ionChange", function (opt) {

                console.log(opt);

                var val_data_hour_ordinario = $(this).attr("id");
                var val_day = val_data_hour_ordinario.split("_")[2];

                that.setFlagFldChanged(val_day);

            });




            $("#btn_avanti_dip").on("click", function (opt) {

                console.log(opt);

                if (window.numEventDipAvanti == 0) {

                    //console.log("CUR DIP: ", that.curResource);

                    window.numEventDipAvanti = window.numEventDipAvanti + 1;

                    var cur_pos = 0;
                    var find_pos = 0;

                    for (var dip of that.dipendenti) {

                        if (that.curResource == dip.fld_name) {
                            find_pos = cur_pos;
                        }

                        cur_pos = cur_pos + 1;

                    }

                    console.log("CUR POS DIP: ", find_pos);

                    find_pos = find_pos + 1;

                    console.log("NEXT POS DIP: ", find_pos);

                    console.log("NEXT DIP: ", that.dipendenti[find_pos]);

                    that.manageClickDipendenti(that.dipendenti[find_pos]);


                }

            });


            $("#btn_indietro_dip").on("click", function (opt) {

                console.log(opt);

                if (window.numEventDipIndietro == 0) {

                    console.log("CUR DIP 1: ", that.curResource);

                    window.numEventDipIndietro = window.numEventDipIndietro + 1;

                    var cur_pos = 0;
                    var find_pos = 0;

                    for (var dip of that.dipendenti) {

                        if (that.curResource == dip.fld_name) {
                            find_pos = cur_pos;
                        }

                        cur_pos = cur_pos + 1;

                    }

                    console.log("CUR POS DIP: ", find_pos);

                    find_pos = find_pos - 1;

                    console.log("NEXT POS DIP: ", find_pos);

                    console.log("NEXT DIP: ", that.dipendenti[find_pos]);

                    that.manageClickDipendenti(that.dipendenti[find_pos]);


                }

            });










        },

        manageEbadge: function () {
            setTimeout(() => {
                // alert ( this.tab );

            }, 300);
        },

        resetDay: function () {
            this.date = "";

        },



        changeStatus: async function (status) {
            console.log(status);
            this.sheetStatus = false;
            var statusInt = 0;

            if (status == "Aperto") statusInt = 1;
            if (status == "For approval") statusInt = 2;
            if (status == "Chiuso") statusInt = 3;
            console.log("Stato int", statusInt);

            //this.$root.$children[0].showProgress = true ;
            this.showSpinner = true;

            var response = await this.updateStatusMonthAdmin(statusInt).then((res) => {
                if (res.status == 200) {
                    // this.$swal("Il timesheet è stato inviato correttamente");
                    this.$swal({
                        icon: "success",
                        text: "Il timesheet è stato aggiornato correttamente",
                        showConfirmButton: false,
                        timer: 500
                    });
                    this.syncMonth();
                } else {
                    this.$swal({
                        icon: "error",
                        text: "Il timesheet non è stato aggiornato correttamente",
                    });
                }
            });
            //this.$root.$children[0].showProgress = false ;
            this.showSpinner = false;

            console.log("Response", response);

        },

        clearSearch() {
            this.search = "";
        },
        clearSearchDipendenti() {
            this.searchDipendenti = "";
        },

        sumHours: function (event) {

            var operation = "+";

            if (event.target.classList.contains('minus')) {
                operation = '-';
            }

            if (event) {
                var dataid = event.target.getAttribute("data-id");
                var disabled = event.target.attributes.getNamedItem('disabled');

                console.log("PO", this.piano_orario);
                console.log("curDay", this.curDay);

                var strDay = new Date(this.curYear, this.curMonth - 1, this.curDay);
                //console.log ("dw", strDay.getDay() );
                var mapDay = {
                    "0": "domenica",
                    "1": "lunedi",
                    "2": "martedi",
                    "3": "mercoledi",
                    "4": "giovedi",
                    "5": "venerdi",
                    "6": "sabato"
                };

                var po = this.piano_orario[0];
                var dayLiteral = mapDay[strDay.getDay()];

                var oretoday = parseFloat(po[dayLiteral]);

                var max = 10;
                max = oretoday;
                var index = 0;
                var newQuant;
                if (dataid == 'Ordinari') index = 0;
                if (dataid == 'Straordinari') index = 1;
                if (dataid == 'Assenza') index = 2;

                console.log("quantita", this.forecast[index].quantity);

                if (disabled == null) {

                    if (operation == '+')
                        newQuant = parseFloat(this.forecast[index].quantity) + 0.5;
                    else
                        newQuant = parseFloat(this.forecast[index].quantity) - 0.5;

                    if (newQuant <= max && operation == '+') {
                        this.forecast[index].quantity = newQuant.toString();

                    }

                    if (newQuant >= 0 && operation == '-') {
                        this.forecast[index].quantity = newQuant.toString();

                    }

                    var diff = 0;
                    //adapta absence with ordinary , user cliecked ordinari so check if more than usual
                    if (dataid == 'Ordinari') {
                        if (newQuant <= oretoday) {
                            diff = oretoday - newQuant;
                            console.log("Diff:", diff);
                            //needed to set absense
                            if (diff > oretoday) diff = oretoday;
                            this.forecast[2].quantity = diff.toString();
                        }
                    }

                    if (dataid == 'Assenza') {
                        if (newQuant <= oretoday) {
                            diff = oretoday - newQuant;
                            console.log("Diff:", diff);
                            //needed to set absense
                            if (diff > oretoday) diff = oretoday;
                            this.forecast[0].quantity = diff.toString();
                        }
                    }

                    this.updateSumDay();
                }

            }

        },

        manageClickDipendenti: function (dipendente) {

            this.sheetDipendenti = false;

            window.numEventDipAvanti = 0;
            window.numEventDipIndietro = 0;

            console.log(dipendente);

            this.currDipendente = dipendente.resourceid;

            this.resetDay();

            this.changeDip();

            setTimeout(() => {

                this.syncMonth();

                //console.log( "dipobj" ,this.currDipendenteObj);
                this.isDipEbadge = parseInt(this.currDipendenteObj.is_ebadge);

                this.entrata1 = "";
                this.entrata2 = "";
                this.uscita1 = "";
                this.uscita2 = "";

            }, 100);

        },

        manageClickAziende: async function (azienda) {

            //console.log(azienda);

            var v_token = window.$cookies.get("token");

            this.currAzienda = azienda;

            // console.log("CUR YEAR", this.curYear);
            // console.log("CUR MONTH", this.curMonth);

            this.$root.$children[0].showProgress = true;

            var response = await apitimesheet.getDipendenti(
                azienda,
                v_token,
                this.curYear,
                this.curMonth
            ).then((res) => {

                this.$root.$children[0].showProgress = false;

                //that.$root.$children[0].showProgress = false;
                console.log("res from getDipendenti", res);


                var arrDipendenti = [];

                for (var dip of res.data.Dipendenti) {

                    arrDipendenti.push(dip);

                }


                this.dipendenti = arrDipendenti;

                setTimeout(() => {
                    this.sheetAziende = false;
                    this.sheetDipendenti = true;
                }, 300);



                //  console.log("ARR DIP: ", arrDipendenti);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }


            );



            // await this.syncArrayDipendenti();

            // console.log("CurAzienda", this.currAzienda);
            // console.log(this.dipendenti);

            // setTimeout(() => {
            //     this.sheetAziende = false;
            //     this.sheetDipendenti = true;
            // }, 300);

        },

        changeDip: function (selectObj) {
            console.log(selectObj);
            /*  console.log("Oggetto :", selectObj);
             console.log(selectObj.src);

             console.log(this.currDipendente);
             console.log(this.dipendenti); */

            //var selNameDip = "";
            for (const [key, dip] of Object.entries(this.dipendenti)) {
                console.log("key", key);
                if (parseInt(dip.resourceid) == this.currDipendente) {
                    this.currDipendenteObj = dip;
                    console.log("dip", dip);
                    //selNameDip = dip.fld_name;

                }
            }

            //  this.$root.$children[0].curDipTop = selNameDip;

        },

        updateStatusMonthAdmin: async function (status) {

            console.log("currDipObj", this.currDipendenteObj);

            //    return false ;// eslint-disable no-unreachable

            var v_token = window.$cookies.get("token");

            var token = window.$cookies.get("token");
            var system = window.$cookies.get("system");
            var user_id = window.$cookies.get("user_id");

            if (this.currDipendenteObj.resourceid !== undefined) {
                //alert (  this.currDipendenteObj.resourceid  );
                system = this.currDipendenteObj.v_res_id;
                token = this.currDipendenteObj.v_user;
                user_id = this.currDipendenteObj.v_user_id;

            }

            var success = 1;
            var response = null;
            try {
                response = await this.$api.tsUpdateStatus(
                    token,
                    system,
                    user_id,
                    status,
                    this.curYear,
                    this.curMonth,
                    v_token
                );
            } catch (error) {
                success = 0;
                console.log(error);
            }
            console.log(response, status, success, token, system, user_id);
            return response;
        },

        updateStatusMonth: async function (status) {
            var token = window.$cookies.get("token");
            var system = window.$cookies.get("system");
            var user_id = window.$cookies.get("user_id");
            //  var username = window.$cookies.get("username");
            //system => resourceid
            //token_system=>pass encodata
            //username

            var success = 1;
            var response = null;

            var v_key = this.generateRandomString(50, "gWnKdU7d4Sb58hFbL4G6");
            v_key = v_key + "|" + token.toString();
            v_key = v_key + "|" + system.toString();
            v_key = v_key + "|" + user_id.toString();
            v_key = v_key + "|" + status.toString();
            v_key = v_key + "|" + this.curYear.toString();
            v_key = v_key + "|" + this.curMonth.toString();
            v_key = v_key + "|" + this.generateRandomString(50, "gWnKdU7d4Sb58hFbL4G6");
            v_key = btoa(v_key);
            v_key = this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6") + v_key + this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");

            try {
                response = await this.$api.tsUpdateStatusApprovalNew(
                    v_key
                );
            } catch (error) {
                success = 0;
                console.log(error);
            }

            console.log(response, status, success);

            return response;
        },

        setupNavTop: async function () {
            // this.setupNavBottomDone = true ;
            /*   var disabled = false;
              if (this.curStatus == "Close" || this.curStatus == "For Approval") {
                disabled = true;
              } */


            var icon = "mdi-account-lock-open";
            var descStatus = "Aperto";
            if (this.curStatus == "Close") {
                icon = "mdi-account-lock";
                descStatus = "Chiuso";
            }
            if (this.curStatus == "For approval") {
                icon = "mdi-account-arrow-right";
                descStatus = "In Approvazione";
            }


            var color = this.colorsStati[this.curStatus];

            console.log("Color status :", color);

            var buttonsTop = [{
                text: "",
                icon: icon,
                link: "",
                id: "btn_status",
                disabled: false,
                color: color,
                title: descStatus
            }];

            this.$root.$children[0].buttonsTop = buttonsTop;
            //console.log ( icon );

            this.$root.$children[0].buttonsTop = [];

            $("#user_red").hide();
            $("#user_yellow").hide();
            $("#user_green").hide();

            if (descStatus == "Aperto") {

                $("#user_red").show();


            }

            if (descStatus == "In Approvazione") {

                $("#user_yellow").show();

            }


            if (descStatus == "Chiuso") {

                $("#user_green").show();

            }

        },

        setupNavBottom: async function () {

            var a = window.$cookies.get("a");
            var b = window.$cookies.get("b");


            var is_partita_iva = window.$cookies.get("is_partita_iva");


            // console.log("VALORE AA: ", this.aa);
            // console.log("VALORE SU: ", this.su);

            console.log("VALORE A: ", a);
            console.log("VALORE B: ", b);

            this.setupNavBottomDone = true;
            var disabled = false;
            $(".salva").show();

            if (this.curStatus == "Close" || this.curStatus == "For approval") {
                disabled = true;
                $(".salva").hide();
            }

            if (this.aa == 'Y' || this.aa == 'S' || this.su == 'Y' || this.su == 'S') {
                disabled = false;
            }

            var icon = "mdi-account-lock-open";
            if (this.curStatus == "Close") icon = "mdi-account-lock";
            if (this.curStatus == "For approval") icon = "mdi-account-arrow-right";

            console.log(icon);

            setTimeout(() => {

                var pulsantis = [];


                if (window.curProgetto != "") {
                    disabled = true;
                }



                if (!disabled) {

                    pulsantis.push(

                        {
                            text: "Ricarica",
                            icon: "mdi-refresh",
                            image: "https://app.emtool.eu/public/_lib/img/refresh-emt.png",
                            link: "",
                            id: "btn_refr_tms",
                            disabled: false,
                            title: "Aggiorna",
                            width: 30
                        },



                    );

                    pulsantis.push(



                        {
                            text: "",
                            icon: "mdi-refresh",
                            image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                            link: "",
                            id: "btn_save",
                            disabled: disabled,
                            title: "Salva",
                            width: 28
                        },



                    );

                    pulsantis.push(



                        {
                            text: "",
                            icon: "mdi-refresh",
                            image: "https://app.emtool.eu/public/_lib/img/cambio-stato-emt.png",
                            link: "",
                            id: "btn_send_tms",
                            disabled: disabled,
                            title: "Invia Per Approvazione",
                            width: 35
                        },



                    );

                    pulsantis.push(



                        {
                            text: "",
                            icon: "mdi-refresh",
                            image: this.getUrlDomain("public/_lib/img/enable_disable_buttons_emt.png"),
                            link: "",
                            id: "btn_en_dis_interv",
                            disabled: false,
                            title: "Abilita / Disabilita Intervento",
                            width: 35
                        },



                    );

                    if (is_partita_iva == 0) {

                        pulsantis.push(



                            {
                                text: "",
                                icon: "mdi-refresh",
                                image: this.getUrlDomain("public/_lib/img/malattia4.png"),
                                link: "",
                                id: "btn_go_disease",
                                disabled: false,
                                title: "Malattia",
                                width: 30
                            },



                        );

                        pulsantis.push(



                            {
                                text: "",
                                icon: "mdi-refresh",
                                image: this.getUrlDomain("public/_lib/img/ferie2.png"),
                                link: "",
                                id: "btn_go_ferie",
                                disabled: false,
                                title: "Ferie / Permessi",
                                width: 30
                            },



                        );


                    }




                }




                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 10);
        },

        resetSumDay: async function () {
            /*  this.totPresenzaMese = monthPresenza;
                  this.totStraordinariMese = monthStraordinary; */

            this.forecast[0].quantity = "";
            this.forecast[1].quantity = "";
            this.forecast[2].quantity = "";
            this.forecast[3].quantity = "";
            this.forecast[4].quantity = "";
        },

        roundstaff: function (val) {
            console.log(val);

            return val.toFixed(2);

        },
        updateSumTotals: function () {
            //update all
            var result = Object.entries(this.monthArray);
            console.log("qui", result);
            var monthPresenza = 0;
            var monthStraordinary = 0;
            var monthAssenza = 0;
            var monthReperibilita = 0;
            var monthIntervento = 0;

            var ordinary = "";
            var straordinary = "";
            var assenza = "";
            var reperibilita = "";
            var intervento = "";
            var is_include = 0;

            for (var dat of result) {
                ordinary = dat[1].hour_ordinario;
                straordinary = dat[1].hour_straordinario;
                assenza = dat[1].hour_activity;
                reperibilita = dat[1].hour_reperibilita;
                intervento = dat[1].hour_intervento;


                is_include = 1;

                if (window.curProgetto != "") {

                    if (parseInt(window.curProgetto) != parseInt(dat[1].id_progetto)) {


                        is_include = 0;



                    }



                }


                if (is_include == 1) {


                    if (ordinary > 0) monthPresenza += parseFloat(ordinary);
                    if (straordinary > 0) monthStraordinary += parseFloat(straordinary);
                    if (assenza > 0) monthAssenza += parseFloat(assenza);
                    if (reperibilita > 0) monthReperibilita += parseFloat(reperibilita);
                    if (intervento > 0) monthIntervento += parseFloat(intervento);

                }


            }
            this.totPresenzaMese = this.roundstaff(monthPresenza);
            this.totStraordinariMese = this.roundstaff(monthStraordinary);
            this.totAssenzaMese = this.roundstaff(monthAssenza);

            this.totReperibilitaMese = this.roundstaff(monthReperibilita);

            this.totInterventoMese = this.roundstaff(monthIntervento);

            var totali = parseFloat(monthPresenza) + parseFloat(monthStraordinary);

            this.$root.$children[0].curDipOreTop = "";
            this.$root.$children[0].curDipOreTopLabel = this.roundstaff(totali) + " H";

        },
        updateSumDay: async function () {
            var token = window.$cookies.get("token");
            var system = window.$cookies.get("system");

            var username = window.$cookies.get("username");
            console.log(username);

            var ord = this.forecast[0].quantity;
            var straord = this.forecast[1].quantity;
            var ass = this.forecast[2].quantity;
            var id_ass = this.forecast[3].quantity;

            if (ass == 0 || ass == "0") {
                id_ass = "";
                this.forecast[3].quantity = "";

            }

            this.forecast[4].quantity = parseFloat(ord) + parseFloat(straord);

            console.log("meseArray", this.monthArray);

            this.monthArray[this.curDay].hour_ordinario = ord;
            this.monthArray[this.curDay].hour_activity = ass;
            this.monthArray[this.curDay].hour_straordinario = straord;
            this.monthArray[this.curDay].idtypeactivity = id_ass;

            //trial
            this.updateSumTotals();

            //last modify update calendar icons
            this.syncMOnthIcons();

            //Call Ws tsUpdateDay async without blocking ui
            //v_ore_presenza,v_ore_assenza,v_ore_straordinari,v_id_assenza

            //if admin os su
            if (this.currDipendenteObj.resourceid !== undefined) {

                system = this.currDipendenteObj.v_res_id;
                token = this.currDipendenteObj.v_user;
                //user_id = this.currDipendenteObj.v_user_id;

            }

            try {
                var response = this.$api
                    .tsDayUpdate(token, system, this.curDay, this.curYear, this.curMonth, ord, ass, straord, id_ass)
                    .then((res) => {
                        console.log(res);
                    });
                console.log(response);
            } catch (error) {
                console.log(error);
            }
        },

        syncArrayDipendenti: async function () {

            console.log(this.currDipendente);

            // this.currDipendente = "";
            // this.$root.$children[0].curDipTop = "";

            this.$root.$children[0].curDipOreTop = "";
            this.$root.$children[0].curDipOreTopLabel = "";

            //aziende
            var arrAziende = [];
            var arrDipendenti = [];

            var aziende = this.globalArrAziende; // Object.entries( this.monthArray.aziende  ) ;
            //   var aziende =   this.aziende  ;

            console.log("Elenco aziende:", this.aziende);
            // this.currAzienda
            //console.log ( "aziendeOK" , aziende );
            for (var az of aziende) {

                var currArAzienda = az[1].fld_name;
                console.log("az", az);
                arrAziende.push(az[1].fld_name);

                var dipArray = az[1].dipendenti;
                for (var dip of dipArray) {
                    //console.log ( dip.fld_name );
                    //  console.log ( dip );
                    if (currArAzienda == this.currAzienda) {
                        arrDipendenti.push(dip);

                    }
                }

            }
            //this.aziende = arrAziende;
            this.dipendenti = arrDipendenti;

        },

        reloadGrid() {

            console.log("RELOADGRID");


            var params = this.getParamsApi();

            console.log("RELOAD_GRID");
            console.log("CUR_YEAR: ", this.curYear);
            console.log("CUR_MONTH: ", this.curMonth);
            console.log("TOKEN_USER: ", atob(params.token));
            console.log("CUR_FULL_NAME_RES: ", this.curResource);
            console.log("CUR_AZIENDA: ", this.currAzienda);

            console.log("CUR_DIPENDENTI: ", this.dipendenti);

            if (this.dipendenti.length == 0) {
                $("#sec_move_dip").hide();
            }

            if (this.dipendenti.length > 0) {
                $("#sec_move_dip").show();


                // abilito le frecce a seconda se sono sul primo o l'ultimo elemento

                var cur_pos = 0;
                var find_pos = 0;

                for (var dip of this.dipendenti) {

                    if (this.curResource == dip.fld_name) {
                        find_pos = cur_pos;
                    }

                    cur_pos = cur_pos + 1;

                }

                $("#btn_indietro_dip").show();
                $("#btn_avanti_dip").show();

                if (find_pos == 0) {

                    $("#btn_indietro_dip").hide();

                }

                console.log("FIND POS: ", find_pos);
                console.log("DIP LEN: ", this.dipendenti.length);

                if (find_pos == (this.dipendenti.length - 1)) {

                    $("#btn_avanti_dip").hide();

                }


            }


            window.anno = this.curYear;
            window.mese = this.curMonth;
            window.user_selected = atob(params.token);

            //window.cur_full_name_res = this.curResource;
            window.cur_full_name_res = this.currAzienda;

            //  $("#full_name_res").html(this.curResource);

            if (this.currAzienda != "") {

                $("#full_name_res").html(this.currAzienda);

                $("#name_dip_sel").html(this.curResource);


                window.table.ajax.reload();

            }
            else {


                this.loadAziendaUserLogged();

            }






        },

        loadAziendaUserLogged: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apitimesheet.getAziendaByUser(
                v_token
            ).then((res) => {

                console.log("res from getAziendaByUser", res);

                $("#full_name_res").html(res.data.Azienda);

                $("#name_dip_sel").html(this.curResource);


                window.table.ajax.reload();


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        getParamsApi() {


            console.log("CUR DIP 2: ", this.currDipendente);


            var curDip = parseInt(this.currDipendente);
            var token = window.$cookies.get("token");
            var system = window.$cookies.get("system");

            if (curDip > 0) {
                console.log("curDIp", curDip, "this.dipendenti", this.dipendenti);
                //for( var dip in this.dipendenti  )
                for (const [key, dip] of Object.entries(this.dipendenti)) {
                    console.log("key", key);
                    if (parseInt(dip.resourceid) == curDip) {
                        console.log("dip", dip, "token", token, "system", system);
                        token = dip.v_user;
                        system = dip.resourceid;

                    }
                }

            }
            console.log({
                "token": token,
                "system": system
            });
            return {
                "token": token,
                "system": system
            };
        },

        syncMOnthIcons: function () {
            var arrTimesheet = this.monthArray;

            var todos = [];

            //var sabdom = [] ;
            for (var day of Object.entries(arrTimesheet)) {
                // console.log("giorno", day[1]);
                var dayCur = day[1];
                var strDay = new Date();

                //check plan hour
                var today = 8;
                if (dayCur.hour_ordinario < today && (dayCur.day_working != "NOT WORKING" &&
                    (dayCur.desc_day != "Sab" && dayCur.desc_day != "Dom"))) {
                    //  console.log (1);

                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: dayCur.hour_ordinario /* "Ordinari  " + dayCur.hour_ordinario  */ /*  this.causali [ dayCur.idtypeactivity].description + "]" */,
                        dates: [strDay],
                        color: "green",
                        isComplete: false,
                        bar: {
                            color: "red"
                        },
                        dot: false
                    });

                }

                //Green for workingday
                if (dayCur.hour_ordinario > 0) {

                    console.log("Ordinari");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: "Ordinari  " + dayCur.hour_ordinario /*  this.causali [ dayCur.idtypeactivity].description + "]" */,
                        dates: [strDay],
                        color: "green",
                        isComplete: true,
                        dot: true
                    });

                }

                //illness
                //console.log ("ok" , this.causali [ dayCur.idtypeactivity]);
                if (dayCur.hour_activity > 0) {

                    console.log("fesilnesst");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: "Assenze " /*  this.causali [ dayCur.idtypeactivity].description + "]" */,
                        dates: [strDay],
                        color: "red",
                        isComplete: false,
                        dot: true
                    });
                    //   datesOk.push ( strDay );
                    //  descOk.push ( dayCur.desc_festività );
                }

                if (dayCur.is_festivo) {
                    console.log("fest");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: dayCur.desc_festività,
                        dates: [strDay],
                        color: "red",
                        isComplete: false,
                        dot: true
                    });
                    //   datesOk.push ( strDay );
                    //  descOk.push ( dayCur.desc_festività );
                }
                if (
                    dayCur.day_working == "NOT WORKING" &&
                    (dayCur.desc_day == "Sab" || dayCur.desc_day == "Dom")
                ) {
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: "Non lavorativo",
                        dates: [strDay],
                        color: "blue",
                        isComplete: false,
                        dot: true
                    });
                }
            }
            this.todos = todos;

        },

        syncMonth: async function () {

            console.log("SYNC MONTH");


            var params = this.getParamsApi();

            console.log("PARAMS: ", params);


            /*          var token = window.$cookies.get("token");
                        var system = window.$cookies.get("system"); */

            var token = params.token;
            var system = params.system;

            //   console.log ( system );
            this.showSpinner = true;
            var response;
            var arrTimesheet = [];

            console.log("TOKEN", token);
            console.log("SYSTEM", system);
            console.log("CUR YEAR", this.curYear);
            console.log("CUR MONTH", this.curMonth);

            try {
                response = await this.$api
                    .GetTimesheet(token, system, this.curYear, this.curMonth)
                    .then((res) => {
                        arrTimesheet = res.data.Timesheet;
                        console.log("Timesheet 2", res.data.Timesheet);

                        this.monthArray = arrTimesheet;

                        var result = Object.entries(arrTimesheet);
                        console.log(result);

                        var info_month = arrTimesheet.info_month;

                        // this.$root.$children[0].curDipTop = info_month.full_name;

                        this.curResource = info_month.full_name;

                        if (!this.setupNavBottomDone) {
                            this.su = arrTimesheet.u_complete;
                            this.aa = arrTimesheet.az_complete;

                        }

                        this.curStatus = info_month.desc_stato;
                        this.curReadOnly = info_month.editable;
                        this.piano_orario = arrTimesheet.piano_orario;

                        console.log("info_month", info_month);

                        var causali = arrTimesheet.causali;
                        console.log("causali", causali);
                        var arrCausali = [];

                        for (var causale of causali) {
                            //arrCausali[causale.idtypeactivity] = causale.description  ;
                            arrCausali.push(causale.description);
                        }
                        // console.log ( arrCausali );
                        this.causali = causali;

                        //aziende
                        var arrAziende = [];
                        var arrDipendenti = [];
                        console.log(arrDipendenti);

                        //console.log("AZIENDE: ", arrTimesheet.aziende);

                        var aziende = Object.entries(arrTimesheet.aziende);
                        //console.log ( "aziendeOK" , aziende );
                        for (var az of aziende) {
                            console.log("az", az);
                            arrAziende.push(az[1].fld_name);
                        }

                        arrAziende.sort();

                        console.log("AZIENDE SORT: ", arrAziende);


                        if (arrAziende.length > 1 && !this.setupNavBottomDone) {

                            //attention
                            this.aziende = arrAziende;
                            this.dipendenti = arrDipendenti;
                            this.globalArrAziende = aziende;



                        }

                        console.log("arraz", arrAziende);
                        console.log("globalArrAziende", this.globalArrAziende);

                        this.syncMOnthIcons();

                        // this.setupGrid();

                        this.reloadGrid();

                    });
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            console.log(response);

            /*       try {
              console.log(response);
             // arrTimesheet = response.data.Timesheet ;
              this.monthArray = arrTimesheet;
            } catch (error) {
              console.log(error  );
            } */

            //  this.data.days = response.data ;

            this.resetSumDay();
            this.updateSumTotals();

            //Setup status at top
            this.setupNavTop();

            //if ( !this.setupNavBottomDone)
            this.setupNavBottom();

        },
        pageChange(obj) {

            /*  if (   this.$root.$children[0].menuAziende  )
               {
                       this.dipendenti =  this.$root.$children[0].dipendenti;
                       this.aziende =  this.$root.$children[0].aziende;

               } */

            window.curProgetto = "";

            this.resetDay();

            this.monthArray = [];
            this.dayFormatted = "";
            // console.log(obj);
            this.curYear = obj.year;
            this.curMonth = obj.month;
            this.forecast[0].quantity = 0;
            this.forecast[1].quantity = 0;
            this.forecast[2].quantity = 0;
            this.forecast[3].quantity = 0;
            this.forecast[4].quantity = 0;

            this.entrata1 = "";
            this.entrata2 = "";
            this.uscita1 = "";
            this.uscita2 = "";

            this.day_editable = false;

            this.syncMonth();

            //this.$refs.defCalendar.$emit('refetchEvents');
            //  this.$root.defCalendar.$emit("dayclick");

            setTimeout(() => {
                // this.onDayClick(   {"day":16 , "ariaLabel": "" , "id":"2022-01-17" } );

            }, 4000);

            //enable

        },

        onDayClick(day) {

            this.curDay = day.day;

            this.forecast[0].quantity = 0;
            this.forecast[1].quantity = 0;
            this.forecast[2].quantity = 0;
            this.forecast[3].quantity = 0;
            this.forecast[4].quantity = 0;

            this.entrata1 = "";
            this.entrata2 = "";
            this.uscita1 = "";
            this.uscita2 = "";

            //const idx = this.days.findIndex(d => d.id === day.id);
            console.log(day);
            // this.forecast[0].day = day.ariaLabel;
            if (day) this.dayFormatted = day.ariaLabel;

            try {
                var ord = this.monthArray[day.day].hour_ordinario;
                var straord = this.monthArray[day.day].hour_straordinario;
                var assenza = this.monthArray[day.day].hour_activity;
                var idtypeactivity = this.monthArray[day.day].idtypeactivity;

                console.log("EX", this.monthArray[day.day].entrata1);

                this.entrata1 = this.monthArray[day.day].entrata1.substr(0, 5);
                this.entrata2 = this.monthArray[day.day].entrata2.substr(0, 5);
                this.uscita1 = this.monthArray[day.day].uscita1.substr(0, 5);
                this.uscita2 = this.monthArray[day.day].uscita2.substr(0, 5);

                //alert (this.entrata1);

                this.day_editable = Boolean(this.monthArray[day.day].day_editable);
                this.forecast[0].quantity = ord;
                this.forecast[1].quantity = straord;
                this.forecast[2].quantity = assenza;
                this.forecast[3].quantity = idtypeactivity;

                this.forecast[4].quantity = parseFloat(ord) + parseFloat(straord);
            } catch (error) {
                console.log(error);
            }
        },
    },

    data() {






        var today = new Date();
        var curMonth = today.getMonth() + 1;
        var curYear = today.getFullYear();
        var curDay = today.getDate();

        var todos = [];

        // dichiarazioni
        return {

            progetti: [],

            searchProgetti: "",

            sheetProgetti: false,

            totReperibilitaMese: "0",

            totInterventoMese: "0",

            countProjAssoc: 0,

            showColumns: false,

            IsPartiIVAPartTime: "N",


            idProjDefault: 0,


            dipendetiElenco: [],

            isDipEbadge: 0,
            entrata1: "",
            entrata2: "",
            uscita1: "",
            uscita2: "",

            curResource: "",

            itemsEbadge: ['Totali', 'Ebadge',],
            tab: null,
            menuAziende: false,
            search: '',
            searchDipendenti: '',
            todos,
            globalArrAziende: {},
            setupNavBottomDone: false,
            currDipendente: "",
            currDipendenteObj: {},
            currAzienda: "",
            su: 'N',
            aa: 'N',
            sheet: false,

            allStatus: [{
                img: 'keep.png',
                title: 'Aperto',
                "id": 1,
                icon: "mdi-account-lock-open",
                "color": "red"
            },
            {
                img: 'hangouts.png',
                title: 'For approval',
                "id": 2,
                icon: "mdi-account-arrow-right",
                "color": "yellow"
            },
            {
                img: 'inbox.png',
                title: 'Chiuso',
                "id": 3,
                icon: "mdi-account-lock",
                "color": "green"
            },
            ],
            tiles: [{
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inboex'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Meessenger'
            },
            {
                img: 'google.png',
                title: 'Gooegle+'
            },
            {
                img: 'keep.png',
                title: 'Keeep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'K3eep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hango3uts'
            },
            {
                img: 'messenger.png',
                title: 'Messen3ger'
            },
            {
                img: 'google.png',
                title: 'Google3+'
            },
            ],

            admindialog: false,
            sheetStatus: false,
            sheetAziende: false,
            sheetDipendenti: false,
            /*  curDayReadOnly:true, */
            totPresenzaMese: "0",
            totAssenzaMese: "0",
            totStraordinariMese: "0",
            causali: [],
            aziende: [],
            dipendenti: [],
            day_editable: false,
            curStatus: "Open",
            piano_orario: {},
            colorsStati: {
                "Close": "green",
                "For approval": "yellow",
                "Open": "red"
            },

            datesPoint: [new Date("2021-12-12"), new Date("2021-12-13")],
            showSpinner: false,
            curMonth: curMonth,
            curYear: curYear,
            curDay: curDay,

            monthArray: [],
            labels: ["SU", "MO", "TU", "WED", "TH", "FR", "SA"],
            time: 0,
            itemsQuantity: [
                "0",
                "0.5",
                "1",
                "1.5",
                "2",
                "2.5",
                "3",
                "3.5",
                "4",
                "4.5",
                "5",
                "5.5",
                "6",
                "6.5",
                "7",
                "7.5",
                "8",
                "8.5",
                "9",
                "9.5",
                "10",
            ],
            forecast: [{
                day: "Ordinari",
                icon: "mdi-account-hard-hat",
                quantity: "0"
            },
            {
                day: "Straordinari",
                icon: "mdi-account-plus",
                quantity: "0"
            },
            {
                day: "Assenza",
                icon: "mdi-account-minus",
                quantity: "0"
            },
            {
                day: "Causale",
                icon: "mdi-star-minus-outline",
                quantity: "Festività",
            },
            {
                day: "Totale",
                icon: "mdi-expand-all",
                quantity: "8"
            },
            ],

            dayFormatted: "",
            dayAbout: "San Cristoforo",

            attributesOld: [{
                dot: {
                    style: {
                        "background-color": "brown",
                    },
                },
                dates: this.datesPoint
                /*  [
                                   new Date( '2021-12-12'), // Jan 12th
                                   new Date('2021-12-13'), // Jan 26th
                                   new Date(2018, 0, 15), // Jan 15th
                               ] */
                ,
            },

                /*  {
                        dot: "red",
                        dates: this.datesPoint,
                        }, */
            ],

            /*   date: new Date(), */
            date: "",

            /*   "it" : it,
            en: en,
            es: es,
            de: de */
        };
    },

    computed: {

        filteredProgetti() {


            return _.orderBy(this.progetti.filter(item => {
                if (!this.searchProgetti) return this.progetti;
                return (item.toLowerCase().includes(this.searchProgetti.toLowerCase()));
            }), 'headline')
        },

        filteredDipendenti() {

            var elements = _.orderBy(this.dipendenti.filter(item => {
                if (!this.searchDipendenti) return this.dipendenti;
                return (item.full_name.toLowerCase().includes(this.searchDipendenti.toLowerCase()));
            }), 'headline');

            console.log("ELEMENTS: ", elements);

            var arrDip = [];
            var n_row = 1;

            for (var dip of elements) {

                dip.n_dip = n_row;

                n_row = n_row + 1;

                arrDip.push(dip);

            }


            return arrDip;
        },



        filteredAziende() {

            console.log("AZI 1: ", this.aziende);

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        attributes() {
            var ok = 0;
            console.log(ok);
            return [

                // Attributes for todos
                ...this.todos.map((todo) => ({
                    dates: todo.dates,
                    dot: todo.dot ? {
                        color: todo.color,
                        class: todo.isComplete ? "opacity-75" : "",
                    } : false,
                    bar: todo.bar ? todo.bar : false,
                    popover: {
                        label: todo.description,
                        hideIndicator: false,
                    },
                    customData: todo,
                })),
            ];
        },
    },

    components: {
        /*   Datepicker, */

        datepicker,
        RiseLoader,

    },
    // ...
};
</script>

<style>
#timesheetGridUnProj .selprogetti {
    max-width: 23px;
    min-height: 23px;
    margin-left: 107px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: -10px;
}

.cl_input_text_row {
    font-size: 11px !important;
    background-color: white;
    margin-top: 7px;
}

.cl_input_text_row:disabled {
    font-size: 11px !important;
    background-color: inherit;
    margin-top: 7px;
}

.cl_ora_interv {
    border-bottom: 1px solid #ced4da;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 4px;
    padding-left: 0px;
    margin-top: 10px;
    --background: white;
}


.cont_ts_un_prog .val_status {
    width: 10px !important;
}

.cont_ts_un_prog .val_sede_cliente {
    width: 10px !important;
}


.clRowHolidaysPermitsUser {
    display: flex;
    margin-left: 10px;
}



#myTable .dataTable tbody tr {
    padding: 5px !important;
}






.deleteRow img {
    width: 16px;
    cursor: pointer;
}

#timesheetGridUnProj .v-list-item {
    margin-left: 20px;
}

#timesheetGridUnProj select:focus-visible {
    outline: 1px dashed rgb(247, 247, 247);
}

#timesheetGridUnProj .v-select__slot {
    font-size: 10px;
}

#timesheetGridUnProj .v-list-item__title {
    font-size: 12px;
}

#timesheetGridUnProj .vc-day {
    min-height: 36px !important;
    padding: 8px;
}

#timesheetGridUnProj .contQuanties {
    max-width: 420px !important;
}

#timesheetGridUnProj .vc-title {
    font-size: 12px !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    color: black !important;
}

@media only screen and (max-width: 600px) {

    #timesheetGridUnProj .v-menu__content REMOVE {
        left: 0px !important;
        min-width: 100vw !important;
        top: auto !important;
        bottom: 0px !important;
        position: fixed !important;
        min-height: 200px;
        /*   min-height: 300px; */

        background: #80808091;
        background: white;

        /*  pointer-events: none; */

    }

    #timesheetGridUnProj .v-menu__content .v-select-listREMOVE {
        max-height: 100%;
        bottom: 0px;
        position: absolute;
        overflow: auto;
        min-width: 100vw;
    }

    #timesheetGridUnProj .vc-day {
        min-height: 2px !important;
        padding: 6px 8px;
    }

    #timesheetGridUnProj .contQuanties {
        max-width: 320px !important;
    }
}

#timesheetGridUnProj span.titletotals {
    color: black;
    font-weight: bold;
    display: flex;
    align-items: center;
}

#timesheetGridUnProj .totals {
    transform: translate(0px, 14px);
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    /*  text-transform: uppercase; */
    font-size: 12px;
    margin-top: -18px;
}

#timesheetGridUnProj .contDayFormatted {}

#timesheetGridUnProj .dayFormatted {
    color: black !important;
    font-weight: bolder;
}

#timesheetGridUnProj .spanStatus {
    color: red;
}

#timesheetGridUnProj .rowStatus {
    display: none;
}

#timesheetGridUnProj .progressBar {
    position: fixed;
    top: 25%;
    left: 25%;
    position: fixed;
    justify-content: center;
    display: flex;
    right: 0;
    left: 0;
    z-index: 100000;
    width: 100%;
    top: 0;
}

#timesheetGridUnProj .contcalendar .v-card__actions {
    padding: 0px !important;
    padding-top: 0px !important;
}

#timesheetGridUnProj .nocolor {
    border: none !important;
    box-shadow: none !important;
}

#timesheetGridUnProj {
    padding-top: 0px;
    max-width: 90vw;
    margin: 0 auto;
}

#timesheetGridUnProj .contcalendar {
    min-width: 60vw;
    /*
  min-height: 60vh; */
    align-content: center;
}

#timesheetGridUnProj .vc-container {
    --day-content-height: 50px;
    --day-content-width: 50px;
}

#timesheetGridUnProj .vc-container.vc-is-expanded {
    border: none;
}

#timesheetGridUnProj .selectHour {
    padding-left: 25px;
    font-size: 12px !important;
}

#timesheetGridUnProj .v-list-item {
    min-height: 42px;
}

#timesheetGridUnProj .v-list-item__icon {
    margin: 10px 0px;
}

#timesheetGridUnProj .totaleCombo input {
    color: black !important;
    font-weight: 600;
}

#timesheetGridUnProj html:not(.hydrated) body {
    display: block;
}

#timesheetGridUnProj .vc-header {
    font-family: 'Montserrat' !important;

}

#timesheetGridUnProj .plus,
#timesheetGridUnProj .minus {
    background: #f5cf2e;
    border: 1px solid;
    padding: 0px 4px;
    cursor: pointer;
    max-height: 24px;
    min-width: 24px;
    text-align: center;

}


#timesheetGridUnProj .text-left {
    min-height: 10px !important;
    max-height: 10px !important;
}

#timesheetGridUnProj .plus {
    margin-right: 6px;
}

#timesheetGridUnProj .addbuttons {
    display: flex;
    margin-left: 5px;
    align-items: center;
    font-size: 20px;
    padding-bottom: 12px;

    /* border: 1px solid; */
}

#timesheetGridUnProj .conSelect {
    display: flex;

}

#timesheetGridUnProj div[disabled] {
    opacity: 0.2;
    cursor: default;
}
</style>

<style>
#timesheetGridUnProj input[type="number"] {
    -moz-appearance: textfield;
}

#timesheetGridUnProj input::-webkit-outer-spin-button,
#timesheetGridUnProj input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

#timesheetGridUnProj .detailtotals {
    display: none;
}

#timesheetGridUnProj .vc-bars {
    max-width: 18px;
    transform: translate(0px, 5px);
}

#timesheetGridUnProj .vc-bar {
    max-height: 1px !important;
}

#timesheetGridUnProj .v-application .text-center {

    overflow: auto;
}

#timesheetGridUnProj .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: inherit !important;
}

#timesheetGridUnProj .v-btn--disabled {
    opacity: 0.3;
}

#timesheetGridUnProj .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 100% !important;
}

#timesheetGridUnProj .itemAzienda {
    margin-left: 15px;
}

.itemNumDip {
    margin-left: 15px;
    max-width: 20px;
}

.itemNameDip {
    margin-left: 15px;
}



#timesheetGridUnProj .headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

#timesheetGridUnProj .closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

#timesheetGridUnProj .closeStatusSheet {
    position: absolute;
    right: 0;
}

#timesheetGridUnProj .v-dialog__content {
    margin-top: 56px;
}

#timesheetGridUnProj .vc-weeks {
    display: none !important;
}

.cont_ts_un_prog #myTable {
    margin-right: 3px;
    margin-top: 72px;
}

.cont_ts_un_prog table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_ts_un_prog table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 26px !important;
}

.cont_ts_un_prog th {
    padding-left: 5px !important;
}

.cont_ts_un_prog ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_ts_un_prog .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}

.cont_ts_un_prog div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cont_ts_un_prog #myTable_wrapper {
    padding-top: 0px;
    margin-top: 6px !important
}

.cont_ts_un_prog .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

@media screen and (max-width: 960px) {

    .cont_ts_un_prog .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_ts_un_prog #myTable {
        /* margin-top:54px; */
    }

    .cont_ts_un_prog #myTable_filter {
        top: 103px;
        position: fixed;

    }

}

.cont_ts_un_prog input[type=Search] {
    display: none !important;
}

.cont_ts_un_prog ion-item {
    --min-height: 20px;
}

.cont_ts_un_prog .dataTable tbody tr td {
    vertical-align: middle;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    padding-left: 6px !important;
}

#timesheetGridUnProj .vc-pane-container {
    margin-top: -7px;
}

.salva {
    max-width: 25px;
    min-height: 25px;
    margin-left: -2px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.salvaeapri {
    max-width: 27px;
    min-height: 27px;
    margin-left: -2px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.salvaechiudi {
    max-width: 27px;
    min-height: 27px;
    margin-left: 4px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.pdfamministrativo {
    max-width: 24px;
    min-height: 24px;
    margin-left: 4px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.pdfcompleto {
    max-width: 25px;
    min-height: 25px;
    margin-left: 4px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
    white-space: normal !important;
}

.item.sc-ion-label-ios-h,
.item .sc-ion-label-ios-h {
    white-space: normal !important;
}

.cont_ts_un_prog .day_red {
    color: red;
}


.cont_ts_un_prog .day_blue {
    color: blue;
}


.cont_ts_un_prog .day_green {
    color: green;
}

.cont_ts_un_prog .day_black {
    color: black;
}

.name_res {
    font-weight: bold;
    padding-left: 5px;
    transform: translateY(-4px);
}


.cont_ts_un_prog #myTable_filter {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: flex-start !important;
    min-height: 70px !important;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    padding-top: 3px !important;
    background-color: white;
    border-color: #f5cf2e;
}


#timesheetGridUnProj .cont_buttons {

    width: 122px;
    min-width: 122px;

}

.cont_names {
    /* min-width: 100%; */
    /* background-color: red; */
    max-width: 93%;
    display: flex;
    flex-direction: column;
    min-width: 1;
    min-width: 100%;
}

.name_res {
    font-weight: bold;
    padding-left: 5px;
    transform: translate(3px, 8px);
}

/* .move_dip {
    position: absolute;
    transform: translate(0px, 34px);
} */



.move_dip {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    min-width: 100%;
    left: 0;
    transform: translate(0px, 38px);
}

.cl_h_forzatura {
    transform: translateX(-15px) !important;
}

.cl_h_ordinario {
    transform: translateX(-17px) !important;
}

.cl_h_straordinario {
    transform: translateX(-16px) !important;
}

.cl_h_assenza_ore_1 {
    transform: translateX(-16px) !important;
}

.cl_h_assenza_tipo_1 {
    transform: translateX(-16px) !important;
}

.cl_h_assenza_ore_2 {
    transform: translateX(-17px) !important;
}

.cl_h_assenza_tipo_2 {
    transform: translateX(-18px) !important;
}

.cl_h_totale {
    transform: translateX(-18px) !important;
}

.cl_h_descrizione {
    transform: translateX(-16px) !important;
}

.cl_h_progetto {
    transform: translateX(-15px) !important;
}





@media screen and (max-width: 1024px) {

    .cont_ts_un_prog .table-responsive {
        padding: 0px;
        min-width: 80vw;
        max-width: 100vw;
        left: 0px;
        position: fixed;

    }

    .cont_ts_un_prog #myTable {
        max-width: 100vw;
        margin-right: 0px;
        min-width: 100vw;
        position: relative;
        /*   left: 12px; */
        width: 100vw !important;
        margin-top: 70px;
    }

    .cont_ts_un_prog .cl_view {
        display: inline-flex;
    }

    #timesheetGridUnProj .vc-pane-container {
        margin-top: -8px;
    }

    #timesheetGridUnProj .totals {
        margin-top: -18px !important;
    }

    .cont_ts_un_prog #myTable_wrapper {
        padding-top: 0px;
        margin-top: 6px !important;
        max-width: 100vw !important;
        overflow-x: hidden !important;
    }

    .cont_ts_un_prog ion-select {
        max-width: 100px !important;
        word-break: break-all !important;
    }

    .cont_ts_un_prog ion-select label {
        max-width: 100px !important;
        word-break: break-all !important;
    }

    .cont_ts_un_prog .cl_tipo_ass_1 {
        max-width: 100px !important;
        margin-top: -4px !important;
    }

    .cont_ts_un_prog .day_red {
        color: red !important;
        min-width: 40px !important;
        margin-top: -4px;
        min-height: 30px !important;
        padding-top: 7px !important;
    }

    .cont_ts_un_prog .day_green {
        color: green !important;
        min-width: 40px !important;
        margin-top: -4px;
        min-height: 30px !important;
        padding-top: 7px !important;
    }

    .cont_ts_un_prog .day_black {
        color: black !important;
        min-width: 40px !important;
        margin-top: -4px;
        min-height: 30px !important;
        padding-top: 7px !important;

    }

    .cont_ts_un_prog .cl_giorno {
        margin-left: 18px;
    }

    .cont_ts_un_prog .col_ordinario {
        margin-top: -4px !important;
    }

    .cont_ts_un_prog .cl_eccedenze {
        margin-top: -4px !important;
    }

    .cont_ts_un_prog .cl_ore_ass_1 {
        margin-top: -4px !important;
    }



    .cont_ts_un_prog #myTable_filter {
        display: flex;
        flex-direction: row;
        align-items: flex-start !important;
        min-height: 70px !important;
        padding-top: 3px !important;
    }

    #timesheetGridUnProj .cont_buttons {

        width: 122px;
        min-width: 122px;

    }

    .cont_names {
        max-width: 93%;
        display: flex;
        flex-direction: column;
        min-width: 1;
        min-width: 100%;
    }

    .name_res {
        font-weight: bold;
        padding-left: 5px;
        transform: translate(3px, 8px);
    }

    .move_dip {
        position: absolute;
        transform: translate(0px, 34px);
    }


    .cl_h_forzatura {}

    .cl_h_ordinario {}

    .cl_h_straordinario {}

    .cl_h_assenza_ore_1 {}

    .cl_h_assenza_tipo_1 {}

    .cl_h_assenza_ore_2 {}

    .cl_h_assenza_tipo_2 {}

    .cl_h_totale {}

    .cl_h_descrizione {}

    .cl_h_progetto {}





}
</style>
